import { Fragment, useState, useEffect, useContext } from 'react'
import * as _ from 'lodash'
import LogTable from './LogTable'
import PublishLogTable from './PublishLogTable'
import { useParams } from 'react-router-dom'
import { ModalContext } from '../../context/ModalContext'

const UserActionLog = ({
  publishLogs,
  undoPublishLog,
  contentfulLogs,
  onPublishClickCallback,
  siteProcess,
}) => {
  const [timerRunning, setTimerRunning] = useState()
  let { siteId } = useParams()

  const combinedUnpublishedEntries =
    (publishLogs?.length || 0) + (contentfulLogs?.length || 0)

  const { isSaving } = useContext(ModalContext)

  // disable the oublish button for 5 minutes on click
  useEffect(() => {
    if (localStorage.getItem(`publishTimer-${siteId}`)) {
      const currentTimer = localStorage.getItem(`publishTimer-${siteId}`)
      const timeLeft = new Date() - currentTimer

      const readableTime = {
        minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
      }
      // console.log(
      //   'timeLeft: ' + readableTime.minutes + ':' + readableTime.seconds
      // )

      if (readableTime.minutes >= 5) {
        setTimerRunning(false)
      } else {
        setTimerRunning(true)
      }
    }
  }, [])

  return (
    <div className="rw-modal__wrapper">
      {(publishLogs && publishLogs.length > 0) ||
      (contentfulLogs && contentfulLogs.length > 0) ? (
        <Fragment>
          <div className="rw-modal-title">
            <p className="rw-modal__unpublished-updates">
              You have{' '}
              {combinedUnpublishedEntries > 0
                ? combinedUnpublishedEntries
                : 'no'}{' '}
              unpublished update(s). <br />
              Make sure you make all desired changes before pressing Publish.
              Publishing may take up to 15 minutes. You will receive an email
              when your changes are live. This CANNOT be undone.
            </p>

            <button
              type="button"
              className="rw-modal-publish-button"
              onClick={onPublishClickCallback}
              disabled={
                (siteProcess && siteProcess.status == 'queued') ||
                isSaving == 'loading'
                  ? true
                  : false || timerRunning
              }
            >
              Publish
            </button>
            {/* <button
              type="button"
              className="rw-modal-discard-button"
              onClick={undoAllActioLogsCallback}
            >
              discard all updates
            </button> */}
          </div>
          {/* <LogTable
            contentfulLogs={contentfulLogs}
            logs={logs}
            undoActioLogCallback={undoActioLogCallback}
          /> */}
          <PublishLogTable
            logs={publishLogs}
            contentfulLogs={contentfulLogs}
            undoPublishLog={undoPublishLog}
          />
        </Fragment>
      ) : (
        <div
          className="rw-modal-publish-button__no-changes-msg"
          style={{ alignItems: 'center', textAlign: 'center' }}
        >
          <h2>There are no currently saved changes!</h2>
        </div>
      )}
    </div>
  )
}

export default UserActionLog
