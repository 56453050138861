import UpdatedHeader from 'src/components/UpdatedHeader/UpdatedHeader'

import { MainStyles } from './UpdatedMainLayout.styles'

const UpdatedMainLayout = ({ children, userId }) => {
  return (
    <>
      <UpdatedHeader userId={userId}/>
      <MainStyles>
        <main className="main-content">{children}</main>
      </MainStyles>
    </>
  )
}

export default UpdatedMainLayout
