import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import * as Msal from 'msal'
import { msalConfig } from '../../../auth/authConfig'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { fileMessages } from '../../constants/messages/en'

const ContactPage = () => {
  const {
    ShareServiceClient,
    StorageSharedKeyCredential,
  } = require('@azure/storage-file-share')
  const containerAccount = 'easyeditorfileupload'
  const sas =
    '?sv=2019-12-12&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-11-06T22:34:26Z&st=2020-11-06T15:34:26Z&spr=https,http&sig=%2FTqiq%2FVmL5pB%2FE%2FceIGwb5C1yOsUWjvZLklIhKfFL5Q%3D'
  const serviceClientWithSAS = new ShareServiceClient(
    `https://${containerAccount}.file.core.windows.net${sas}`
  )
  const shareName = 'myupload'
  const directoryName = 'easyeditoruploads'

  const authClient = new Msal.UserAgentApplication(msalConfig)
  const account = authClient.getAccount()
  const [loading, setLoading] = useState(false)
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [fileName, setFileName] = useState()
  const [image, setImage] = useState()
  let location = useLocation()
  const [filesToSend, setFilesToSend] = useState([])

  const { register, handleSubmit, errors, formState } = useForm()

  const { isSubmitted } = formState

  const onSubmit = (data) => {
    image ? (data.image = image) : ''
    setLoading(true)
    const url =
      'https://prod-60.westus.logic.azure.com:443/workflows/8327ce627ef7491da5d2fab6ef9a1362/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=mGQMxSRFfvxldPZVVznfBw4-OyMSxhREG0XtSWjIkdc'
    fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(function (response) {
      setLoading(false)
      setEmailSuccess(true)
      ReactGA.event({
        category: 'Support Email',
        action: `${account?.name} Submitted a support ticket`,
        label: `Value: ${data.message}`,
      })
    })
  }

  async function main(file) {
    if (file.size >= 2097152) {
      alert(fileMessages.FILE_TOO_BIG)
      return
    }
    const fileName = file.name.replace(' ', '')

    setFilesToSend((oldArray) => [
      ...oldArray,
      `<a href="https://easyeditorfileupload.file.core.windows.net/myupload/easyeditoruploads/${fileName}${sas}" download> ${fileName} </a>`,
    ])
    const directoryClient = serviceClientWithSAS
      .getShareClient(shareName)
      .getDirectoryClient(directoryName)

    const content = file
    const fileClient = directoryClient.getFileClient(fileName)
    await fileClient.create(file.size)

    // Upload file range
    await fileClient.uploadRange(content, 0, file.size)
  }

  let onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = e.target.files
      // var fileresults
      for (var i = 0; i < files.length; i++) {
        // const formData = new FormData()
        // const reader = new FileReader()

        const file = files[i]
        // formData.append(file.name, file)

        // reader.onload = function () {
        //   fileresults = reader.result
        // }
        // reader.readAsArrayBuffer(file)
        main(file)

        // console.log('onSelectFile -> formData', formData)
        // fetch(
        //   `https://easyeditorfileupload.file.core.windows.net/myupload/easyeditoruploads/test-${file.name}?comp=range&sv=2019-12-12&ss=bfqt&srt=sco&sp=rwdlacupx&se=2021-11-06T22:34:26Z&st=2020-11-06T15:34:26Z&spr=https,http&sig=%2FTqiq%2FVmL5pB%2FE%2FceIGwb5C1yOsUWjvZLklIhKfFL5Q%3D`,
        //   {
        //     method: 'PUT',
        //     body: formData,
        //     headers: {
        //       // 'Content-Length': '0',
        //       'x-ms-write': 'clear',
        //       // 'x-ms-range': 'bytes=0-200000',
        //       'x-ms-type': 'file',
        //       // 'x-ms-range': 'bytes=0-300000',
        //       'x-ms-content-length': '165394',
        //       // 'x-ms-write': 'Update',
        //       // 'x-ms-file-permission': 'Inherit',
        //       // 'x-ms-file-attributes': 'Temporary',
        //       // 'x-ms-file-creation-time': 'now',
        //       // 'x-ms-file-last-write-time': 'now',
        //     },
        //   }
        // )
        //   .then((response) => response.json())
        //   .then((data) => console.log(data))
        //   .catch((error) => console.error(error))
      }

      // setFileName(e.target.files[0].name)
      // const reader = new FileReader()
      // reader.addEventListener('load', () =>
      //  setImage(reader.result)
      // );

      // reader.readAsDataURL(e.target.files[0])
      // reader.onload = function () {
      //   const imgBase64 = reader.result
      //   setImage(imgBase64)

      // var binary = atob(reader.result.split(',')[1])
      // var array = []
      // for (var i = 0; i < binary.length; i++) {
      //   array.push(binary.charCodeAt(i))
      // }

      // image64 = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
      // }
      // reader.onerror = function (error) {
      //   console.log('Error: ', error)
      // }
    }
  }
  let removeImage = (index) => {
    var array = [...filesToSend]
    if (index !== -1) {
      array.splice(index, 1)
      setFilesToSend(array)
    }
    setImage()
    setFileName()
  }

  return (
    <UpdatedMainLayout>
      <form
        className="w-full max-w-lg container mx-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" value={account?.name} name="name" ref={register} />
        <input type="hidden" value={filesToSend} name="image" ref={register} />

        {location?.state?.website && (
          <input
            type="hidden"
            value={location.state.website}
            name="website"
            ref={register}
          />
        )}
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <input
              ref={register}
              className="pointer-events-none appearance-none block w-full bg-gray-200 text-gray-500 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              name="email"
              type="hidden"
              value={account?.userName}
            />
          </div>
        </div>
        <div className="mb-6">
          <h3 className="text-teal-600 text-2xl py-2">
            Contact Marketing Support
          </h3>
          <span className="text-dark-500 py-2">
            Need other changes to your site? Questions or suggestions about Easy
            Editor? Send us a message here or email us at{' '}
            <a href="mailto:marketing@nva.com">marketing@nva.com</a>. We'll get
            back to you right away.
          </span>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Subject *
            </label>
            <input
              ref={register({ required: true })}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              name="subject"
              type="text"
              placeholder="How can we help you?"
            />
            <ErrorMessage
              errors={errors}
              message="Subject is required"
              name="subject"
              as="span"
              className="text-red-600"
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Message *
            </label>
            <textarea
              ref={register({ required: true })}
              className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
              name="message"
              placeholder="Add your message here..."
            ></textarea>
            <ErrorMessage
              errors={errors}
              message="Please enter your message"
              name="message"
              as="span"
              className="text-red-600"
            />
          </div>
          <div className="flex flex-col px-3">
            <label
              style={{
                textDecoration: 'underline',
                color: '#0a8287',
                display: 'flex',
                cursor: 'pointer',
              }}
            >
              {/* <CaptureIcon className="w-4 fill-current text-teal-500 mr-2" />{' '} */}
              Add attachments{' '}
              <input
                className="pb-8 hidden"
                type="file"
                // accept="image/*"
                // multiple
                onChange={onSelectFile}
              />
            </label>
            {filesToSend
              ? filesToSend.map((file, index) => {
                  return (
                    <div
                      key={file}
                      onClick={(e) => removeImage(index)}
                      className="mt-3 bg-white text-gray-800 font-bold rounded border-b-2 border-teal-500 hover:border-teal-500 hover:bg-teal-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center"
                    >
                      <div dangerouslySetInnerHTML={{ __html: file }} />
                      <svg
                        className="ml-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentcolor"
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                        />
                      </svg>
                      {/* <button className="mt-3 bg-white text-gray-800 font-bold rounded border-b-2 border-red-500 hover:border-red-600 hover:bg-red-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                        <span className="mr-2">{file.replace('"', '')}</span>

                      </button> */}
                    </div>
                  )
                })
              : ''}
          </div>
        </div>
        <div className="md:flex md:items-center">
          {loading ? (
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
          ) : !emailSuccess ? (
            <div className="md:w-1/3">
              <input
                type="submit"
                className="shadow bg-teal-600 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4"
                value="Send"
              />
            </div>
          ) : (
            ''
          )}
          <div className="md:w-3/3 text-teal-500">
            {emailSuccess ? 'Your message was sent successfully' : ''}
          </div>
        </div>
      </form>
    </UpdatedMainLayout>
  )
}

export default ContactPage
