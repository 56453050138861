import { Fragment, useState, useEffect, forwardRef } from 'react'
import * as _ from 'lodash'
import MaterialTable from 'material-table'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import { CSVLink } from 'react-csv'
import SearchIcon from '@material-ui/icons/Search'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import HoursTable from '../UserActionLog/HoursTable'
import AdditionalText from '../UserActionLog/AdditionalText'
import EmergencyBanner from '../UserActionLog/EmergencyBanner'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const UserActionLogs = ({ logs }) => {
  const [searchVal, setSearchVal] = useState('')
  const [modLogs, setModLogs] = useState([])
  const today = new Date().toDateString().replace(/ /g, '_')
  useEffect(() => {
    if (logs) {
      // filter out only those logs that are equal to the searched string in the input
      const res = logs.filter((i) => {
        const logStr = i.websiteName && i.websiteName.toLowerCase()
        const searchStr = searchVal && searchVal.toLowerCase()
        if (logStr) {
          return logStr.includes(searchStr)
        }
      })
      setModLogs(res)
    }
  }, [searchVal, logs])

  let newdata = logs && [...logs]
  let formattedData = []
  // creates the new source data to CSV download
  // eslint-disable-next-line no-unused-vars
  let newData =
    newdata &&
    newdata.map((item) => {
      let modifiedHours, oldHours
      if (item.modifiedHoursTable && item.modifiedHoursTable.length >= 0) {
        item.modifiedHoursTable.map((day) => {
          let formattedDayName =
            day.day.charAt(0).toUpperCase() + day.day.slice(1)
          oldHours = `${formattedDayName} - ${day.startOld} to ${day.endOld}`
          modifiedHours = `${formattedDayName} - ${day.startNew} to ${day.endNew}`
        })
      }

      let newResults = {
        Date: item.date,
        'Item Update': item.update,
        Email: item.email,
        Website: item.websiteName,
        ID: item.id,
        'Yext Id': item.yextId,
        'Table Data': item.tableData && item.tableData.id,
        'Old Hours': oldHours,
        'Modified Hours': modifiedHours,
        'Old Extended Additional Text': item.oldExtendedAdditionalText,
        'Modified Extended Additional Text':
          item.modifiedExtendedAdditionalText,
        'Additional Text': item.additionalText,
        'Modified Additional Text': item.modifiedAdditionalText,
      }

      formattedData.push(newResults)

      return item
    })

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }

  return (
    <div className="rw-user-action-logs__container">
      <div className="rw-user-action-logs__header">
        <TextField
          className="rw-user-action-logs__search-bar"
          id="input-with-icon-textfield"
          value={searchVal}
          onChange={(newValue) => setSearchVal(newValue.target.value)}
          placeholder="Search sites"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: '#007F85' }} />
              </InputAdornment>
            ),
          }}
        />
        <div className="rw-user-action-logs__csv-container">
          <GetAppIcon style={{ color: '#007F85' }} />
          <Button className="rw-user-action-logs__csv-container-button">
            <CSVLink
              asyncOnClick={true}
              filename={`Easy_Editor_User_Log_${today}.csv`}
              data={formattedData}
            >
              Download CSV
            </CSVLink>
          </Button>
        </div>
      </div>
      <div>
        {logs && logs.length > 0 ? (
          <div className="rw-changes-tables">
            <MaterialTable
              icons={tableIcons}
              options={{
                search: false,
                filtering: true,
                toolbar: true,
                headerStyle: { backgroundColor: '#c9cbd1' },
                pageSize: 10,
                sorting: true,
                pageSizeOptions: [10, 20, 100, 200],
              }}
              columns={[
                {
                  title: 'DATE & TIME',
                  field: 'date',
                },
                {
                  title: 'UPDATE',
                  field: 'update',
                },
                {
                  title: 'UPDATED BY',
                  field: 'email',
                },
                {
                  title: 'User ID',
                  field: 'userID',
                },
                {
                  title: 'WEBSITE NAME',
                  field: 'websiteName',
                },
                {
                  title: 'WEBSITE ID',
                  field: 'websiteID',
                },
              ]}
              data={modLogs}
              detailPanel={[
                {
                  icon: () => <ArrowForwardIosIcon />,
                  render: (rowData) => {
                    return (
                      <Fragment>
                        {rowData.modifiedHoursTable &&
                          rowData.modifiedHoursTable.length > 0 && (
                            <HoursTable
                              modifiedHoursTable={rowData.modifiedHoursTable}
                              title="Hours of Operation"
                            />
                          )}
                        {rowData.modifiedExtendedHoursTable &&
                          rowData.modifiedExtendedHoursTable.length > 0 && (
                            <HoursTable
                              modifiedHoursTable={
                                rowData.modifiedExtendedHoursTable
                              }
                              title="Extended Hours of Operation"
                            />
                          )}

                        {typeof rowData.modifiedAdditionalText !==
                          'undefined' &&
                          typeof rowData.additionalText !== 'undefined' && (
                            <AdditionalText
                              oldAdditionalText={rowData.additionalText}
                              modifiedAdditionalText={
                                rowData.modifiedAdditionalText
                              }
                              title="Additional Text"
                            />
                          )}

                        {typeof rowData.modifiedExtendedAdditionalText !==
                          'undefined' &&
                          typeof rowData.oldExtendedAdditionalText !==
                            'undefined' && (
                            <AdditionalText
                              oldAdditionalText={
                                rowData.oldExtendedAdditionalText
                              }
                              modifiedAdditionalText={
                                rowData.modifiedExtendedAdditionalText
                              }
                              title="Extended Additional Text"
                            />
                          )}

                        {typeof rowData.modifiedEmergencyBanner !==
                          'undefined' &&
                          typeof rowData.oldEmergencyBanner !== 'undefined' && (
                            <EmergencyBanner
                              oldEmergencyBanner={rowData.oldEmergencyBanner}
                              modifiedEmergencyBanner={
                                rowData.modifiedEmergencyBanner
                              }
                            />
                          )}
                      </Fragment>
                    )
                  },
                },
              ]}
            />
          </div>
        ) : (
          <Fragment>No Logs Found</Fragment>
        )}
      </div>
    </div>
  )
}

export default UserActionLogs
