import React, { useState, useCallback, useEffect } from 'react'
import {
  getUserActionLogsByUserUnpublished,
  getAllContentfulLogs,
} from './src/api/userActionLogs'
import * as Msal from 'msal'
import { msalConfig, loginRequest } from './auth/authConfig'

export const Context = React.createContext()

const Store = ({ children }) => {
  const [isAlertActive, setIsAlertActive] = useState()

  const authClient = new Msal.UserAgentApplication(msalConfig)
  const account = authClient.getAccount()

  const getLogsRest = useCallback(async () => {
    // get action logs from state

    const email = account ? account.userName : ''
    const response = await getUserActionLogsByUserUnpublished({
      email,
    })
    const data = response.data.userActionLogsByUserUnpublished

    if (Object.keys(data).length > 0 || data.length > 0) {
      // localStorage.setItem('isUnpublished', true)
      setIsAlertActive(true)
    } else {
      setIsAlertActive(false)
    }
  })

  useEffect(() => {
    getLogsRest()
  }, [])

  return (
    <Context.Provider value={[isAlertActive, setIsAlertActive]}>
      {children}
    </Context.Provider>
  )
}

export default Store
