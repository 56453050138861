import { Link, routes } from '@redwoodjs/router'
const MaintenancePage = () => {
  return (
    <>
      <div className="loader" id="MaintenancePage">
        <img
          src="https://images.ctfassets.net/rt5zmd3ipxai/4kNkHMUlF1cpoVip76jlEv/2ac543acccec42966b6546cf1aead3a3/animation_640_klgx1usa.gif"
          alt="Animated image of dog walking"
        />
        <article>
          ​<h1>We’ll be back soon!</h1>
          <div>
            <p>
              Sorry for the inconvenience but we are performing some maintenance
              at the moment. You can always email us at
              <a href="mailto:marketing@nva.com">marketing@nva.com</a>,
              otherwise we’ll be back online shortly!
            </p>
            <p>— NVA Web Team</p>
          </div>
        </article>
      </div>
      ​
    </>
  )
}

export default MaintenancePage
