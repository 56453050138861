// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

// import { Router, Route, Private } from '@redwoodjs/router'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import * as Msal from 'msal'
import { useState, useEffect, useCallback } from 'react'

import { useAuthDataContext } from '../auth/authProvider'
import { msalConfig, loginRequest } from '../auth/authConfig'
import PrivateRoute from '../auth/privateRoute'

import UserActionLogsPage from './pages/UserActionLogsPage/UserActionLogsPage'
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ContactPage from './pages/ContactPage'
import NewUserPage from './pages/NewUserPage'
import EditUserPage from './pages/EditUserPage'
import UserPage from './pages/UserPage'
import UsersPage from './pages/UsersPage'
import AdminPage from './pages/AdminPage'
import DashboardPage from './pages/DashboardPage'
import HoursOfOperationPage from './pages/HoursOfOperationPage'
import SettingsPage from './pages/SettingsPage'
import MaintenancePage from './pages/MaintenancePage'

const authClient = new Msal.UserAgentApplication(msalConfig)
const account = authClient.getAccount()

import AboutPageService from './services/about-page.service'
import WebsitePagePage from './pages/WebsitePagePage/WebsitePagePage'
import { EmergencyBanner } from 'src/components/EmergencyBanner/EmergencyBanner'
import { setIsUnderMaintenance } from 'src/hooks/state/state'
import { getConstantByKey } from './api/userActionLogs'
const Routes = () => {
  const [emBannerValue, setEmBannerValue] = useState()
  const [maintenanceMode, setMaintenanceMode] = useState()

  const getMaintenanceMode = async () => {
    // get action logs from state
    const maintenanceModeData = await getConstantByKey('maintenance')
    const data = maintenanceModeData?.data?.getConstantByKey[0]?.value
    data && setMaintenanceMode(data)
  }

  const getEmBannerValue = useCallback(async () => {
    // get action logs from state
    const response = await getConstantByKey('ee_emergency_banner')
    const data = response?.data?.getConstantByKey[0]?.value
    data && setEmBannerValue(data)
  })
  useEffect(() => {
    getEmBannerValue()
    getMaintenanceMode()
  }, [])

  emBannerValue && emBannerValue != '' ? setIsUnderMaintenance(true) : null
  if (maintenanceMode) {
    return (
      <div className="App">
        {emBannerValue && emBannerValue != '' ? (
          <EmergencyBanner text={emBannerValue} />
        ) : null}
        <Switch>
          <Route path="/" component={MaintenancePage} />
        </Switch>
      </div>
    )
  } else {
    return (
      <div className="App">
        {emBannerValue && emBannerValue != '' ? (
          <EmergencyBanner text={emBannerValue} />
        ) : null}
        <Switch>
          <Route path="/about">
            <AboutPage />
          </Route>

          <Route path="/hoursOfOperation" component={HoursOfOperationPage} />
          <PrivateRoute
            path="/contact"
            account={account}
            component={ContactPage}
          />
          <PrivateRoute
            account={account}
            path="/dashboard"
            component={DashboardPage}
          />
          <PrivateRoute
            account={account}
            path="/settings"
            component={SettingsPage}
          />

          <PrivateRoute
            account={account}
            path="/log"
            component={UserActionLogsPage}
          />

          <PrivateRoute
            account={account}
            path="/users/new"
            component={NewUserPage}
          />

          <PrivateRoute
            account={account}
            path="/users/:id/edit"
            component={EditUserPage}
          />

          <PrivateRoute
            account={account}
            path="/users/:id"
            component={UserPage}
          />

          <PrivateRoute account={account} path="/users" component={UsersPage} />
          <PrivateRoute account={account} path="/admin" component={AdminPage} />

          <PrivateRoute
            account={account}
            path="/site/:siteId/website-page/:pageName/:websiteName"
            component={WebsitePagePage}
          />

          <Route path="/" component={HomePage} />
        </Switch>
      </div>
    )
  }
}

export default Routes
