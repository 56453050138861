import { Form, FormError, FieldError, Label, Submit } from '@redwoodjs/forms'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import * as _ from 'lodash'

const UserForm = ({ onSave, error, groups, userToEdit = {} }) => {
  const groupIds = userToEdit?.groups?.map((group) => {
    return { id: group.id, value: group.name }
  })

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      groups: groupIds,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'groups',
  })

  const selectGroups = groups.map((option) => {
    return (
      <option key={option.id} value={option.id}>
        {option.name}
      </option>
    )
  })

  const onSubmit = (data) => {
    //data.groups = parseInt(data.groups.id)
    // data.groups.yextId = parseInt(data.groups.yextId)
    data.groups = (data.groups && data.groups.map((id) => parseInt(id.id))) || 1
    data.roleId = parseInt(data.roleId)
    // data.loginsCount = parseInt(data.loginsCount)

    onSave(data)
  }

  return (
    <div className="rw-form-wrapper">
      <Form onSubmit={handleSubmit(onSubmit)} error={error}>
        <FormError
          error={error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />

        <Label
          name="email"
          className="rw-label"
          // errorClassName="rw-label rw-label-error"
        >
          Email
        </Label>
        <input
          name="email"
          defaultValue={!_.isEmpty(userToEdit) ? userToEdit.email : ''}
          className="rw-input"
          // errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          ref={register()}
        />
        <FieldError name="email" className="rw-field-error" />

        <Label
          name="emailVerified"
          className="rw-label"
          // errorClassName="rw-label rw-label-error"
        >
          Email verified
        </Label>
        <input
          name="emailVerified"
          defaultChecked={
            !_.isEmpty(userToEdit) ? userToEdit.emailVerified : ''
          }
          className="rw-input"
          // errorClassName="rw-input rw-input-error"
          ref={register()}
          type="checkbox"
        />
        <FieldError name="emailVerified" className="rw-field-error" />

        <Label
          name="name"
          className="rw-label"
          // errorClassName="rw-label rw-label-error"
        >
          Name
        </Label>
        <input
          name="name"
          defaultValue={!_.isEmpty(userToEdit) ? userToEdit.name : ''}
          className="rw-input"
          // errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          ref={register()}
        />
        <FieldError name="name" className="rw-field-error" />

        <Label
          name="nickname"
          className="rw-label"
          // errorClassName="rw-label rw-label-error"
        >
          Nickname
        </Label>
        <input
          name="nickname"
          defaultValue={!_.isEmpty(userToEdit) ? userToEdit.nickname : ''}
          className="rw-input"
          // errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          ref={register()}
        />
        <FieldError name="nickname" className="rw-field-error" />

        <Label
          name="picture"
          className="rw-label"
          // errorClassName="rw-label rw-label-error"
        >
          Picture
        </Label>
        <input
          name="picture"
          defaultValue={!_.isEmpty(userToEdit) ? userToEdit.picture : ''}
          className="rw-input"
          // errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          ref={register()}
        />
        <FieldError name="picture" className="rw-field-error" />

        <Label
          name="userId"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          User id
        </Label>
        <input
          name="userId"
          defaultValue={!_.isEmpty(userToEdit) ? userToEdit.userId : ''}
          className="rw-input"
          // errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          ref={register()}
        />
        <FieldError name="groups" className="rw-field-error" />

        <Label
          htmlFor="updatedAt"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Updated At
        </Label>
        <input
          style={{
            pointerEvents: 'none',
            background: 'lightgrey',
          }}
          name="updatedAt"
          defaultValue={new Date().toISOString()}
          className="rw-input"
          // errorClassName="rw-input rw-input-error"
          validation={{ required: true }}
          ref={register()}
        />
        <FieldError name="groups" className="rw-field-error" />

        <Label
          name="roleId"
          className="rw-label"
          // errorClassName="rw-label rw-label-error"
        >
          Role
        </Label>
        <select
          name="roleId"
          defaultValue={!_.isEmpty(userToEdit) ? userToEdit.roleId : ''}
          className="rw-input w-auto"
          // errorClassName="rw-input rw-input-error"
          ref={register()}
        >
          <option value="1">Admin</option>
          <option value="2">User</option>
        </select>
        <FieldError name="role" className="rw-field-error" />

        <Label
          name="groups"
          className="rw-label"
          // errorClassName="rw-label rw-label-error"
        >
          Assigned Websites
        </Label>
        <div className="flex flex-col">
          {fields.map((item, index) => {
            return (
              <div className="flex flex-row space-x-4" key={index}>
                <Controller
                  key={item.id}
                  // as={<select options={selectGroups2} />}
                  as={<select>{selectGroups}</select>}
                  name={`groups[${index}].id`}
                  className="rw-input w-auto"
                  control={control}
                  defaultValue={`${item.id}`}
                  ref={register()}
                />
                <FieldError
                  name={`groups[${index}].id`}
                  className="rw-field-error"
                />
                <button
                  type="button"
                  className="rw-button"
                  onClick={() => remove(index)}
                >
                  Delete
                </button>
              </div>
            )
          })}
          <button
            type="button"
            onClick={() => {
              append(groups[0]?.id)
            }}
            className="rw-button"
          >
            Add
          </button>

          {/* <TextField
            name="groups.name"
            defaultValue={user?.groups[0]?.name}
            className="rw-input w-auto"
            errorClassName="rw-input rw-input-error"
          />
          <FieldError name="group.name" className="rw-field-error" />

          <NumberField
            name="groups.yextId"
            defaultValue={user?.groups[0]?.name}
            className="rw-input w-auto"
            errorClassName="rw-input rw-input-error"
          />
          <FieldError name="group.yextId" className="rw-field-error" /> */}
        </div>
        <div className="rw-button-group">
          <Submit className="rw-button rw-button-blue">
            {!_.isEmpty(userToEdit) ? 'Update' : 'Save'}
          </Submit>
        </div>
      </Form>
    </div>
  )
}

export default UserForm
