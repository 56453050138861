import React from 'react'

const HaveExtendedHours = ({ changes, title }) => {
  console.log('changes --->', changes)
  return (
    <div className="rw-additional-text rw-segment">
      <div className="rw-additional-text__container">
        <div className="rw-additional-text__title">{title}</div>
        <div className="rw-additional-text__content">
          Previous:{' '}
          <span style={{ textDecoration: 'line-through' }}>
            {changes[0].oldValue == 'true' ? 'Enabled' : 'Disabled'}
          </span>
        </div>
        <div className="rw-additional-text__content rw-additional-text__content--updated">
          New: {changes[0].newValue == 'true' ? 'Enabled' : 'Disabled'}
        </div>
      </div>
    </div>
  )
}

export default HaveExtendedHours
