import React, { useState, useEffect } from 'react'
import { useGlobalState } from '../../hooks/state/state'

const Sidebar = ({
  width,
  height,
  isClosed,
  saveChanges,
  saveButtonDisabled,
  children,
}) => {
  const [xPosition, setX] = React.useState(0)
  const [isCurrentUserAdmin] = useGlobalState('isCurrentUserAdmin')
  const [isUnderMaintenance] = useGlobalState('isUnderMaintenance')
  const [headerBottomCoordinate] = useGlobalState('headerBottomCoordinate')

  const toggleMenu = () => {
    if (xPosition < 0) {
      setX(0)
      isClosed(false)
    } else {
      setX(-100)
      isClosed(true)
    }
  }

  const [footerBottomCoordinateOffset, setFooterBottomCoordinateOffset] = useState(0);

  useEffect(() => {
    let footerBottom = document.getElementById('sidebar-footer').getBoundingClientRect().bottom;
    let windowBottom = window.innerHeight;
    let offset = footerBottom > windowBottom ? footerBottom - windowBottom : 0;
    if (!(footerBottomCoordinateOffset == offset)) {
      setFooterBottomCoordinateOffset(offset);
    }

  }, [])

  return (
    <div
      id="sidebar"
      className={`sidebar ${xPosition < 0 ? 'sidebar-closed' : 'sidebar-open'}`}
      style={{
        position: 'fixed',
        top: headerBottomCoordinate ? headerBottomCoordinate : !isCurrentUserAdmin
          ? isUnderMaintenance
            ? '186px'
            : '136px'
          : isUnderMaintenance
            ? '121px'
            : '71px',
        left: '0',
        display: 'block',
        background: 'white',
        height: '100%',
        width: 'var(--tina-sidebar-width)',
        margin: '0',
        padding: '0',
        border: '0 ',
        boxSizing: 'border-box',
        maxWidth: '425px',
      }}
    >
      <button
        onClick={() => toggleMenu()}
        style={{
          right: '-50px',
        }}
        aria-label="toggles cms sidebar"
        className="blue-button"
      >
        {xPosition < 0 ? (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M24.3324 8.96875C24.754 9.42578 25 9.95312 25 10.5859C25 11.2188 24.754 11.7461 24.3324 12.168L11.9634 24.543L7.85212 25C7.57101 25 7.36018 24.9297 7.21962 24.7188C7.04392 24.543 6.97365 24.332 7.00878 24.0508L7.46559 20.043L19.8346 7.66797C20.2562 7.24609 20.7833 7 21.4158 7C22.0483 7 22.5754 7.24609 23.0322 7.66797L24.3324 8.96875ZM11.1903 22.9258L20.3968 13.7148L18.2884 11.6055L9.08199 20.8164L8.80088 23.207L11.1903 22.9258ZM23.1376 10.9727C23.243 10.8672 23.3133 10.7266 23.3133 10.5859C23.3133 10.4453 23.243 10.3047 23.1376 10.1641L21.8375 8.86328C21.6969 8.75781 21.5564 8.6875 21.4158 8.6875C21.2753 8.6875 21.1347 8.75781 21.0293 8.86328L19.4832 10.4102L21.5915 12.5195L23.1376 10.9727Z"></path>
          </svg>
        ) : (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M21 7.208L19.7346 6L10.5227 14.7939C9.82527 15.4597 9.82626 16.5412 10.5227 17.2061L19.7346 26L21 24.792L11.7901 16L21 7.208Z"></path>
          </svg>
        )}
      </button>
      <div
        id="sidebar-form"
        className="sidebar-form"
        style={{
          height: `calc(100% - ${footerBottomCoordinateOffset}px)`,
          padding: '20px 0 40px 20px',
        }}
      >
        {children}
      </div>
      <div
        id="sidebar-footer"
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '66px', // changed it to match other component's sidebar footer
          width: '100%',
          padding: '20px', // changed it to match other component's sidebar footer
          borderTop: '1px solid #edecf3',
        }}
      >
        <div
          id="staff-form-buttons"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <button
            className="staff-save-button"
            onClick={saveChanges}
            style={{
              backgroundColor: '#077f86', // changed it to match the other disabled buttons in the sidebar-footer
              boxShadow: '0px 2px 3px rgba(0,0,0,0.12)',
              color: '#fff',
              cursor: saveButtonDisabled ? 'default' : 'pointer',
              fontSize: '13px',
              height: '40px',
              opacity: saveButtonDisabled ? 0.3 : 1,
              padding: '0 20px',
              width: '290px',
            }}
            disabled={saveButtonDisabled}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
