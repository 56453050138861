import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripLines } from '@fortawesome/free-solid-svg-icons'
import PawLoader from '../../PawLoader'

const Groups = (props) => {
  const [dragging, setDragging] = useState(false)

  const onDragOver = (ev) => {
    ev.preventDefault()
  }

  const onDragStart = (ev, key) => {
    setDragging(true)
    ev.dataTransfer.setData('key', key)
  }

  const onDrop = (ev, destKey) => {
    setDragging(false)
    let sourceKey = ev.dataTransfer.getData('key')
    props.reorderGroups(sourceKey, destKey)
  }

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    const strMsg = JSON.stringify(jsonMsg)
    win.postMessage(strMsg, '*')
  }

  return (
    <div className="staff-form">
      <div>
        <div id="group-title" style={{ marginBottom: '20px' }}>
          <h2 className="sidebar-header">Staff Information</h2>
          <p className="sidebar-para">
            Drag and drop to reorder staff groups. To edit staff members, click
            the group name.
          </p>
        </div>

        <div id="group-add" style={{ marginBottom: '20px' }}>
          <button
            onClick={() => props.addGroup()}
            className={props.addButtonDisabled ? 'disabled' : ''}
          >
            <span style={{ textDecoration: 'underline', color: '#0a8287' }}>
              + Add Group
            </span>
          </button>
        </div>

        <div id="group-groups" style={{ marginBottom: '40px' }}>
          {props.addButtonDisabled ? (
            <PawLoader />
          ) : props.data ? (
            props.data.map((item, key) => {
              if (!item.deleted) {
                return (
                  <div
                    key={key}
                    id={item.key}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 3fr',
                      marginBottom: '20px',
                      cursor: 'grabbing',
                      padding: '10px',
                      alignItems: 'center',
                    }}
                    className={`${dragging ? 'showDropAreas' : ''}`}
                    draggable
                    onDragOver={onDragOver}
                    onDrop={(e) => onDrop(e, key)}
                    onDragStart={(e) => onDragStart(e, key)}
                  >
                    <FontAwesomeIcon icon={faGripLines} />
                    <button
                      onClick={() => {
                        sendMsg({ scrollToStaffId: item.key })
                        props.goGroup(key)
                      }}
                    >
                      <div style={{ textAlign: 'left' }}>
                        {item.groupName || 'No Name'}
                      </div>
                    </button>
                  </div>
                )
              }
            })
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Groups
