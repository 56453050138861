import * as _ from 'lodash'
/**
 * Parse any updated operation hours into a string that contains the day, new and old
 * hours of operation.
 * @param {object} originalHours Original hours from yext
 * @param {object} modifiedHours Modified hours from user form edit
 */
export const parseHoursToString = (originalHours, modifiedHours) => {
  let hoursString = ''
  for (const [key, value] of Object.entries(originalHours)) {
    if (key in modifiedHours) {
      // day is now closed

      if (
        value.isClosed === false &&
        (modifiedHours[key]['isClosed'] ||
          modifiedHours[key]['isClosed'] === 'true')
      ) {
        const { start, end } = value['openIntervals'][0]

        hoursString += ` ^^ day=${key}, startOld=${start}, endOld=${end}, isClosedOld=false, startNew=00:00, endNew=00:00, isClosedNew=true `
      }

      // closed day is now open
      else if (
        value.isClosed === true &&
        !modifiedHours[key].isClosed &&
        modifiedHours[key].openIntervals[0].start &&
        modifiedHours[key].openIntervals[0].end
      ) {
        const { start: modStart, end: modEnd } = modifiedHours[key][
          'openIntervals'
        ][0]
        hoursString += ` ^^ day=${key}, startOld=00:00, endOld=00:00, isClosedOld=true, startNew=${modStart}, endNew=${modEnd}, isClosedNew=false `
      }

      // day was open but hours are modified
      else if (value['openIntervals']) {
        const { start: modStart, end: modEnd } = modifiedHours[key][
          'openIntervals'
        ][0]
        const { start, end } = value['openIntervals'][0]

        // if start time or end time was modified, only include the day for which a change was made
        if (start !== modStart || end !== modEnd) {
          hoursString += ` ^^ day=${key}, startOld=${start}, endOld=${end}, startNew=${modStart}, endNew=${modEnd}`
        }
      }
    }
  }
  return hoursString
}

/**
 * Parse Original hours of operation to a string.
 * @param {object} originalHours original hours object
 */
export const parseOriginalHoursToString = (originalHours) => {
  let hoursString = ''
  for (const [key, value] of Object.entries(originalHours)) {
    // check days for which a start and end time was specified
    if (value['openIntervals'] && !value['isClosed']) {
      const { start, end } = value['openIntervals'][0]
      hoursString += ` ^^ orgDay=${key}, isClosed=false, orgStart=${start}, orgEnd=${end}`
      // if a day which previously had isClosed flag now has hours, include the new data
    } else if (value['openIntervals'] && value['isClosed']) {
      hoursString += ` ^^ orgDay=${key}, isClosed=true, orgStart=00:00, orgEnd=00:00`
    }
  }
  return hoursString
}

export const hideFooterButtonStyles = () => (
  <style>{`
    div[class^='FormFooter-sc'] div button:disabled {
      display: none;
    }
    div[class^='FormFooter-sc'] {
      display: none;
    }
  `}</style>
)

export const showFooterButtonStyles = () => (
  <style jsx>{`
    div[class^='FormFooter-sc'] div button:disabled {
      display: block;
    }
    div[class^='FormFooter-sc'] {
      display: flex;
    }
  `}</style>
)

export const updateHours = (hrs) => {
  const hours = hrs
  if (hours && hours.monday && !('isClosed' in hours.monday)) {
    hours.monday.isClosed = false
  } else {
    if (hours && hours.monday && !hours.monday.openIntervals) {
      hours.monday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.tuesday && !('isClosed' in hours.tuesday)) {
    hours.tuesday.isClosed = false
  } else {
    if (hours && hours.tuesday && !hours.tuesday.openIntervals) {
      hours.tuesday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.wednesday && !('isClosed' in hours.wednesday)) {
    hours.wednesday.isClosed = false
  } else {
    if (hours && hours.wednesday && !hours.wednesday.openIntervals) {
      hours.wednesday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.thursday && !('isClosed' in hours.thursday)) {
    hours.thursday.isClosed = false
  } else {
    if (hours && hours.thursday && !hours.thursday.openIntervals) {
      hours.thursday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.friday && !('isClosed' in hours.friday)) {
    hours.friday.isClosed = false
  } else {
    if (hours && hours.friday && !hours.friday.openIntervals) {
      hours.friday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.saturday && !('isClosed' in hours.saturday)) {
    hours.saturday.isClosed = false
  } else {
    if (hours && hours.saturday && !hours.saturday.openIntervals) {
      hours.saturday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  if (hours && hours.sunday && !('isClosed' in hours.sunday)) {
    hours.sunday.isClosed = false
  } else {
    if (hours && hours.sunday && !hours.sunday.openIntervals) {
      hours.sunday.openIntervals = [
        {
          start: '00:00',
          end: '23:59',
        },
      ]
    }
  }

  return hours
}
