import React, { useState, useEffect } from 'react'
import { withTina, useCMS, useForm, usePlugin } from 'tinacms'
import { useLocation, Link } from 'react-router-dom'
import ArrowLeft from '-!svg-react-loader?name=Icon!../../static/images/arrow.svg'
import ForwardArrow from '-!svg-react-loader?name=Icon!../../static/images/next.svg'

import { hideFooterButtonStyles, updateHours } from './helper'
import './WebsitePagePage.css'
import VideoModal from '../../components/VideoModal'
import HoursAccordion from '../../components/HoursAccordion'
import StaffAccordion from '../../components/StaffAccordion'
import Accordion from '../../components/Accordion'
import Showdown from 'showdown'

import {
  HeadingText,
  SectionLink,
  Paragraph,
} from './WebsitePageHoursEditForm.styles.js'

import {
  useGlobalState,
  setCurrentStaffId,
  setCurrentStaffInfo,
} from '../../hooks/state/state'
import DesktopMobileToggle from './DesktopMobileToggle'
import PreviewFrame from './PreviewFrame'
import ReactGA from 'react-ga'

const WebsitePageMain = ({ user }) => {
  const cms = useCMS()
  const [firstLoadDone, setFirstLoadDone] = useState(false)
  const [sidebarToggleBtn, setSidebarToggleBtn] = useState(false)
  const [iframeView, setIframeView] = useState('large')
  const [showVideo, setShowVideo] = useState(false)
  let location = useLocation()
  const [descriptionInitial] = useGlobalState('initialBanner')
  const [descriptionUpdated] = useGlobalState('updatedBanner')
  const [isBannerUpdated] = useGlobalState('isBannerUpdated')
  const [isHoursUpdated] = useGlobalState('isHoursUpdated')
  const [updatedHours] = useGlobalState('updatedHours')
  const [initialHours] = useGlobalState('initialHours')
  const [isHoursDeleted] = useGlobalState('isHoursDeleted')
  const [currentYextId] = useGlobalState('currentYextId')
  const [isEBDeleted] = useGlobalState('isEBDeleted')
  const [multiLocations] = useGlobalState('multiLocations')
  const [globalStaffPages] = useGlobalState('staffPages')
  const [iframeLoading, setIframeLoading] = useState(true)

  const [selectedSite] = useGlobalState('selectedSite')
  const [updatedSelectedSite] = useGlobalState('updatedSelectedSite')
  const [isSelectedSiteUpdated] = useGlobalState('isSelectedSiteUpdated')
  const [showBannerState] = useGlobalState('isShowBanner')
  const [bannerBackgroundColor] = useGlobalState('bannerBackgroundColor')
  const [websiteTheme] = useGlobalState('websiteTheme')

  const [staffInfo, setStaffInfo] = useState([...globalStaffPages])

  let converter = new Showdown.Converter()
  let description = isBannerUpdated
    ? descriptionUpdated
    : descriptionInitial.text

  let bannerObj = {
    showBanner: showBannerState,
    bannerBackgroundColor,
    websiteTheme,
    author: {
      description,
    },
  }

  let siteId = isSelectedSiteUpdated
    ? updatedSelectedSite.siteId
    : selectedSite.siteId

  let siteName = isSelectedSiteUpdated
    ? updatedSelectedSite.name
    : selectedSite.name

  //setInitialHours(initialHours)

  const initial = updatedHours[currentYextId]
    ? updatedHours[currentYextId]
    : initialHours

  const initialValues = {
    mainHours: {
      hours: updateHours(initial.hours),
      additionalHoursText: initial.additionalHoursText,
    },
    extendedHours: {
      extendedHours: updateHours(initial.c_extendedHours),
      c_extendedAdditionalHoursText: initial.c_extendedAdditionalHoursText,
    },
    haveExtendedHours:
      typeof initial.c_extendedHours !== 'undefined' &&
      Object.keys(initial.c_extendedHours).length > 0,
  }

  useEffect(() => {
    if (isHoursDeleted) {
      setTimeout(() => {
        sendMsg(initialValues)
      }, 2000)
    }

    if (isEBDeleted) {
      setTimeout(() => {
        sendMsg(bannerObj)
      }, 2000)
    }
  }, [isHoursDeleted, descriptionUpdated])

  useEffect(() => {
    setSidebarToggleBtn(
      document.querySelector('[aria-label="toggles cms sidebar"]')
    )
    document.getElementById('websitePreviewFrame').style['pointer-events'] =
      'none'
  }, [])

  useEffect(() => {
    const nvaDontShow = localStorage.getItem('nva-dont-show')
    const isVideoViewed = sessionStorage.getItem('isVideoViewed')
    if (nvaDontShow != null) {
      const users = JSON.parse(nvaDontShow)

      if (!users.includes(user.id) && user.id) {
        setShowVideo(false)
      }
    } else if (isVideoViewed) {
      setShowVideo(false)
    } else {
      setShowVideo(true)
    }
  }, [user.id])

  useEffect(() => {
    setStaffInfo([...globalStaffPages])
    if (globalStaffPages && globalStaffPages.length == 1) {
      globalStaffPages.forEach((staff) => {
        setCurrentStaffId(staff.sys.id)
        setCurrentStaffInfo(staff)
      })
    }
  }, [globalStaffPages])

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    if (jsonMsg) {
      if (jsonMsg.author && jsonMsg.author.description) {
        // convert message to HTML to display in preview.
        let formattedText = converter.makeHtml(jsonMsg.author.description)
        let regex = /<\/?p[^>]*>/gi
        jsonMsg.author.description = formattedText.replace(regex, '')
      }
      const strMsg = JSON.stringify(jsonMsg)
      win?.postMessage(strMsg, '*')
    }
  }

  const sendOnLoadMsg = () => {
    setTimeout(() => {
      if (document.getElementById('websitePreviewFrame')) {
        document.getElementById('websitePreviewFrame').style['pointer-events'] =
          'auto'
      }
      sendMsg(bannerObj)
      sendMsg(initialValues)
      setIframeLoading(false)
    }, 2000)
  }

  // let setCurrentStaffData = () => {
  //   console.log('staffpages: ', tempstaff)
  //   tempstaff.forEach((staff) => {
  //     setCurrentStaffId(staff.sys.id)
  //     setCurrentStaffInfo(staff)
  //   })
  // }

  const [, form] = useForm({
    id: 'links',
    label: '',
    fields: [
      {
        name: 'hidebuttonstyles',
        component: hideFooterButtonStyles,
      },
      {
        name: 'linktoDashboard',
        linkText: 'Dashboard',
        component: () =>
          user &&
          user.roleId &&
          user.roleId != 1 &&
          user.groups &&
          user.groups.length > 1 && (
            <Link
              to={'/dashboard'}
              className="flex flex-row py-2"
              style={{
                borderBottom: '1px solid var(--tina-color-grey-2)',
                width: '100%',
              }}
              onClick={() =>
                ReactGA.event({
                  category: 'Navigation',
                  action: `User ${user.id} Navigated to Dashboard`,
                  label: `siteID: ${siteId}`,
                })
              }
            >
              <ArrowLeft className="w-4 mr-2 text-gray-500 transform rotate-180" />
              Dashboard
            </Link>
          ),
      },
      {
        name: 'lblCustomize',
        component: () => (
          <div className="fp-2 mt-5 mb-5">
            <HeadingText>Welcome!</HeadingText>
            <Paragraph>
              Edit your live website by selecting one of the options below.
              Remember, after finishing and saving all the changes you want to
              make, you need to select the Publish button to make them live.
            </Paragraph>
          </div>
        ),
      },
      {
        name: 'linktoStaff',
        component: () => (
          <div
            onClick={() =>
              ReactGA.event({
                category: 'Navigation',
                action: `User ${user.id} Navigated to Staff Page`,
                label: `siteID: ${siteId}`,
              })
            }
          >
            {staffInfo && staffInfo.length > 0 ? (
              <>
                <h4 className="font-medium">Staff Information </h4>
                <Paragraph>
                  Add, edit and delete staff members and organize them into
                  groups.
                </Paragraph>{' '}
              </>
            ) : (
              ''
            )}
            <SectionLink>
              {staffInfo && staffInfo.length > 0 ? (
                staffInfo.length > 1 ? (
                  <Accordion
                    accordionFor="multiLocationStaff"
                    data={staffInfo}
                    url={`/site/${siteId}/website-page/multi-location-staff/${siteName}`}
                    selectedSite={selectedSite}
                  />
                ) : (
                  <>
                    <Link
                      to={`/site/${siteId}/website-page/staff/${siteName}`}
                      className="flex flex-row p-3 hover:bg-teal-300 justify-between"
                    >
                      Staff Information{' '}
                      <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                    </Link>
                  </>
                )
              ) : (
                ''
              )}
            </SectionLink>
          </div>
        ),
        group: location.state,
      },
      {
        name: 'linkToHours',
        component: () => (
          <div
            onClick={() =>
              ReactGA.event({
                category: 'Navigation',
                action: `User ${user.id} Navigated to Hours Page`,
                label: `siteID: ${siteId}`,
              })
            }
          >
            <h4 className="font-medium">Hours of Operation</h4>
            <Paragraph>
              Edit your hours of operation and any additional information about
              service availability.
            </Paragraph>
            <SectionLink>
              {multiLocations && multiLocations.length > 0 ? (
                <Accordion
                  accordionFor="multiLocationHours"
                  data={multiLocations}
                  url={`/site/${siteId}/website-page/multi-location-hours/${siteName}`}
                  selectedSite={selectedSite}
                />
              ) : (
                <Link
                  to={`/site/${siteId}/website-page/hours/${siteName}`}
                  className="flex flex-row p-3 hover:bg-teal-300 justify-between"
                >
                  Hours of Operation{' '}
                  <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
                </Link>
              )}
            </SectionLink>
          </div>
        ),
        group: location.state,
      },
      {
        name: 'linktoBanner',
        component: () => (
          <div
            onClick={() =>
              ReactGA.event({
                category: 'Navigation',
                action: `User ${user.id} Navigated to Emergency Banner Page`,
                label: `siteID: ${siteId}`,
              })
            }
          >
            <h4 className="font-medium">Emergency Banner</h4>
            <Paragraph>
              Add, edit or remove the banner that appears at the very top of
              your website.
            </Paragraph>
            <SectionLink>
              <Link
                to={`/site/${siteId}/website-page/about/${siteName}`}
                className="flex flex-row p-3 hover:bg-teal-300 justify-between"
              >
                Emergency Banner{' '}
                <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
              </Link>
            </SectionLink>
          </div>
        ),
        group: location.state,
      },
    ],
  })

  usePlugin(form)

  if (!cms.sidebar.isOpen && !firstLoadDone && sidebarToggleBtn) {
    sidebarToggleBtn.click()
    setFirstLoadDone(true)
  }

  return (
    <div className="previewWrapper" style={{ position: 'relative' }}>
      <DesktopMobileToggle
        setIframeView={setIframeView}
        iframeView={iframeView}
        sendOnLoadMsg={sendOnLoadMsg}
      />
      <PreviewFrame
        siteName={selectedSite.name}
        selectedSite={selectedSite.siteURL.split('#')[0]}
        // selectedSite="http://localhost:9000"
        sendOnLoadMsg={sendOnLoadMsg}
        iframeView={iframeView}
        cms={cms}
      />

      {showVideo ? (
        <VideoModal setShowVideo={setShowVideo} userid={user.id} />
      ) : (
        ''
      )}
    </div>
  )
}

export default withTina(WebsitePageMain, {
  enabled: true,
  sidebar: true,
})
