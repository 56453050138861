import { Link } from 'react-router-dom'
import { Image, LogoContainer } from './Logo.styles'
import NVALogo from '../../../static/images/logo-white.png'

const Logo = () => {
  return (
    <LogoContainer>
      <Link to={'/dashboard'}>
        <Image src={NVALogo} />
      </Link>
    </LogoContainer>
  )
}

export default Logo
