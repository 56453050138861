import { request } from '../utils/request'

/**
 * User Action Log REST queries
 */
const queries = {
  allLogs:
    '{userActionLogs{id, userID, userEmail, websiteID, websiteName, contentfulEntryId, contentfulEntryName, isEntryPublished, createdAt}}',
  logsByUserEmail:
    '{id, userID, userEmail, websiteID, contentfulEntryId, contentfulEntryName, isEntryPublished, createdAt }',
  logsById:
    '{id, userID, userEmail, contentfulEntryId, contentfulEntryName, websiteID, isEntryPublished, createdAt}',
  createUserActionLog:
    '{userID, userEmail, contentfulEntryId, contentfulEntryName, websiteID, websiteName, isEntryPublished}',
  getAllContentfulLogs:
    '{getAllContentfulLogs{logId, contentfulId, isPublished, createdAt, contentfulId, id, email, name, nickname, userId, roleId}}',
  createContentfulLog: '{logId}',
  updateContentfulLog: '{logId}',
  getContentfulLogsByUserID:
    '{logId, contentfulId, isPublished, createdAt, contentfulId, email}',
}

/**
 * Get all user action logs.
 */
export const getUserActionLogs = async () =>
  await request('get', queries.allLogs)

/**
 * Get all action logs related to user by email.
 * @param {string} email user's email
 */
export const getUserActionLogsByUser = async ({ email }) => {
  return await request(
    'get',
    `{userActionLogsByUser (userEmail: "${email}")${queries.logsByUserEmail}}`
  )
}
/**
 * Get all action logs related to user by email.
 * @param {ID} ID user's ID
 */
export const getUnpublishedContentfulLogsByUserID = async ({ ID }) => {
  return await request(
    'get',
    `{getContentfulLogsByUserID (fk_contentfullog_user: ${ID})${queries.getContentfulLogsByUserID}}`
  )
}

export const getUserActionLogsByUserUnpublished = async ({ email }) => {
  return await request(
    'get',
    `{userActionLogsByUserUnpublished (userEmail: "${email}")${queries.logsByUserEmail}}`
  )
}

/**
 * Get all action logs related to user by id.
 * @param {string} email user's email
 */
export const getUserActionLogsById = async ({ id }) => {
  return await request(
    'get',
    `{userActionLogsByUser (userID: "${id}")${queries.logsById}}`
  )
}

/**
 * Create new action log.
 */
export const createUserActionLogs = async (query) => {
  return await request(
    'post',
    `mutation{createUserActionLogs (input: ${query}) ${queries.createUserActionLog}}`,
    true
  )
}

/**
 * Update action log.
 */
export const updateUserActionLogs = async (query) => {
  return await request(
    'post',
    `mutation{updateUserActionLogs (input: ${query}) ${queries.createUserActionLog}}`,
    true
  )
}

/**
 * Delete user action log.
 * @param {string} id action log id
 */
export const deleteActionLogById = async (id) => {
  return await request(
    'post',
    `mutation{deleteActionLogById (id: ${id}) {id}}`,
    true
  )
}

/**
 * Get all Contentful action logs.
 */
export const getAllContentfulLogs = async () =>
  await request('get', queries.getAllContentfulLogs)

/**
 * Create new action log entry for Contentful.
 */
export const createContentfulLog = async (query) => {
  return await request(
    'post',
    `mutation{createContentfulLog (input: ${query}) ${queries.createContentfulLog}}`,
    true
  )
}

/**
 * Update new action log entry for Contentful.
 */
export const updateContentfulLog = async (query) => {
  return await request(
    'post',
    `mutation{updateContentfulLog (input: ${query}) ${queries.updateContentfulLog}}`,
    true
  )
}

/**
 * Get Constants from the DB, by key.
 */
export const getConstantByKey = async (key) => {
  return await request(
    'get',
    `{getConstantByKey (key: "${key}") {value}}`,
    false
  )
}

/**
 * Set Constants in the DB, by key.
 */
export const updateConstant = async ({ key, value }) => {
  return await request(
    'post',
    `mutation {updateConstant(input: {key: ${key}, value: "${value}"}){value}"}`,
    true
  )
}
