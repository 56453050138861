import {
  setInitialHours,
  setCurrentYextId,
  setCurrentStaffId,
  setCurrentStaffInfo,
  setYextResponse,
} from 'src/hooks/state/state'

export const getHoursAccordionData = (
  accordionFor,
  data,
  url,
  selectedSite
) => {
  let accordionData = {}
  accordionData['title'] = 'Hours of Operation'
  accordionData['options'] = data.map((each) => {
    let optionData = {}
    optionData['pathName'] = url
    optionData['name'] = each['name']
    optionData[accordionFor] = {
      siteURL: `${selectedSite.siteURL?.split('#')[0]}/${each['url']}`,
      locationName: each['name'],
    }
    optionData['onClick'] = () => {
      setInitialHours(each.hours)
      setCurrentYextId(each.yextId)
      setYextResponse(each.hours)
    }
    return optionData
  })
  return accordionData
}

export const getStaffAccordionData = (
  accordionFor,
  data,
  url,
  selectedSite
) => {
  let accordionData = {}
  accordionData['title'] = 'Staff Information'
  accordionData['options'] = data.map((each) => {
    let optionData = {}
    optionData['pathName'] = url
    optionData['name'] = each.fields.title
    optionData[accordionFor] = {
      siteURL: `${selectedSite.siteURL?.split('#')[0]}/${each.fields.url}`,
    }
    optionData['onClick'] = () => {
      setCurrentStaffId(each.sys.id)
      setCurrentStaffInfo(each)
    }
    return optionData
  })
  return accordionData
}
