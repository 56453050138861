import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { getUserById } from '../../api/users'
import { Link, useHistory } from 'react-router-dom'
import { deleteUserByEmail } from 'src/api/users'
import * as _ from 'lodash'
import PawLoader from '../PawLoader'
import { userMessages } from '../../constants/messages/en'

const UserCellRest = ({ id }) => {
  const history = useHistory()
  const onDeleteClick = async (email) => {
    if (confirm(userMessages.CONFIRM_DELETE_USER + email + '?')) {
      const deletedUser = await deleteUserByEmail(email)

      if (deletedUser.data.deleteUserByEmail.email == null)
        history.push('/dashboard')
    }
  }
  const [user, setUser] = useState([])
  const [isLoading, setLoadingStatus] = useState(true)

  /**
   * Get a user by email from REST API.
   */
  const getUserRest = useCallback(async () => {
    let userObj = {}
    // get user
    const usersRest = await getUserById({ id })
    const { data } = usersRest
    userObj = data.userByID
    setUser(userObj)
    setLoadingStatus(false)
  }, [id])

  useEffect(() => {
    getUserRest()
  }, [getUserRest])

  return (
    <Fragment>
      {!_.isEmpty(user) ? (
        <Fragment>
          <div className="rw-segment">
            <header className="rw-segment-header">
              <h1 className="rw-heading rw-heading-secondary text-center">
                User {user.id} Details
              </h1>
            </header>
            <table className="rw-table">
              <tbody>
                <tr>
                  <th>Id:</th>
                  <td>{user.id}</td>
                </tr>
                <tr>
                  <th>Created At:</th>
                  <td>{new Date(user.createdAt).toString()}</td>
                </tr>
                <tr>
                  <th>Updated At:</th>
                  <td>{new Date(user.updatedAt).toString()}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <th>Email Verified:</th>
                  <td>{user.emailVerified ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th>Last Ip:</th>
                  <td>{user.lastIp}</td>
                </tr>
                <tr>
                  <th>Last Login:</th>
                  <td>{new Date(user.lastLogin).toString()}</td>
                </tr>
                <tr>
                  <th>Logins Count:</th>
                  <td>{user.loginsCount}</td>
                </tr>
                <tr>
                  <th>Name:</th>
                  <td>{user.name}</td>
                </tr>
                <tr>
                  <th>Nickname:</th>
                  <td>{user.nickname}</td>
                </tr>
                <tr>
                  <th>Picture:</th>
                  <td>
                    <img
                      style={{ maxWidth: '150px' }}
                      src={user.picture}
                      alt={user.nickname}
                    />
                  </td>
                </tr>
                <tr>
                  <th>User Id:</th>
                  <td>{user.userId}</td>
                </tr>
                <tr>
                  <th>Role</th>
                  <td>{user.roleId === 1 ? 'Admin' : 'User'}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <nav className="rw-button-group">
            <Link
              to={`/users/${user.id}/edit`}
              className="rw-button rw-button-blue"
            >
              Edit
            </Link>
            <a
              href="#"
              className="rw-button rw-button-red"
              onClick={() => onDeleteClick(user.email)}
            >
              Delete
            </a>
          </nav>
        </Fragment>
      ) : (
        <PawLoader />
      )}
    </Fragment>
  )
}

export default UserCellRest
