import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
// import UserCell from 'src/components/UserCell'
import { useParams } from 'react-router-dom'
import UserCellRest from 'src/components/UserCellRest'

const UserPage = ({ role }) => {
  let { id } = useParams()
  if (role != 1) {
    return (
      <UpdatedMainLayout>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }

  const userId = parseInt(id)

  return (
    <UpdatedMainLayout>
      {/* <UserCell id={userId} /> */}
      <UserCellRest id={userId} />
    </UpdatedMainLayout>
  )
}

export default UserPage
