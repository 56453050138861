import { Link } from 'react-router-dom'
import UserGroupDetails from './UserGroupsDetails'
import { Card, CardWrapper } from '../UserGroupCell.styles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const UserGroupsCard = ({ group, imgSrc }) => {
  const baseSiteURL = group.siteURL.replace(/#.*$/, '')

  return (
    <CardWrapper>
      <Link
        to={{
          pathname: `/site/${group.siteId}/website-page/main/${group.name}`,
          state: {
            siteId: group.siteId,
            siteURL: group.siteURL,
            yextId: group.yextId,
            name: group.name,
          },
        }}
      >
        <Card>
          <div className="object-cover w-full shadow-inner h-11">
            <LazyLoadImage
              alt={'Site Screenshot'}
              effect="blur"
              src={`https://webshot.deam.io/${baseSiteURL}?width=1280&height=720`} // use normal <img> attributes as props
            />
          </div>

          <UserGroupDetails name={group.name} yextId={group.yextId} />
        </Card>
      </Link>
    </CardWrapper>
  )
}

export default UserGroupsCard
