import React, {useState} from 'react'
import * as Msal from 'msal'
import { msalConfig } from '../../../../auth/authConfig'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

let UserDropDown = () => {

  const myMSALObj = new Msal.UserAgentApplication(msalConfig)
  const account = myMSALObj.getAccount()
  const [showDropdown, setShowDropdown] = useState(false)

  let signOut = () => {
    if(sessionStorage.getItem('isVideoViewed')) {
      sessionStorage.removeItem('isVideoViewed');
    }
    if (account) myMSALObj.logout()
  }

  return <div className="my-auto">
    <button
      className="flex flex-row text-white focus:outline-none mr-6"
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <AccountCircleIcon className="mr-2"/>
      {account && account.name ? account.name : ''}
      <KeyboardArrowDownIcon className="pl-2 text-white" />
    </button>
    {showDropdown ?
      <div className="absolute flex flex-col pb-6 bg-white shadow-2xl user-dropdown-contents z-10">
        <a
          className="py-2 px-6 cursor-pointer underline hover:bg-teal-100 hover:text-teal-500"
          onClick={async () => signOut()}
        >
          {account ? 'Log Out' : 'Log In'}
        </a>
      </div>
      :''
    }
  </div>

}

export default UserDropDown;