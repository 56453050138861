import UpdatedMainLayout from '../../layouts/UpdatedMainLayout/UpdatedMainLayout'
// import UsersCell from 'src/components/UsersCell'
import UsersCellRest from 'src/components/UsersCellRest'

const UsersPage = ({ role }) => {
  if (role != 1) {
    return (
      <UpdatedMainLayout>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }
  return (
    <UpdatedMainLayout>
      {/* <UsersCell /> */}
      <UsersCellRest />
    </UpdatedMainLayout>
  )
}

export default UsersPage
