import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  forwardRef,
} from 'react'
import { getUserActionLogs, getAllContentfulLogs } from 'src/api/userActionLogs'
import UserActionLogs from 'src/components/UserActionLogs'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import { prepareLogHelper } from '../UserActionLog/helper'
import PawLoader from '../PawLoader'
import {
  parseUserActionLogChanges,
  formatEmergencyBanner,
} from '../UserActionLogsByUserCellRest/helper'
import * as _ from 'lodash'
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const UserActionLogsCellRest = () => {
  const [logs, setLogs] = useState([])
  const [contentfulLogs, setContentfulLogs] = useState()
  const [isLoading, setLoadingStatus] = useState(true)
  const [source, setSource] = useState('yext')

  /**
   * Get a logs from REST API.
   */
  const getActionLogsRest = useCallback(async () => {
    setContentfulLogs('')
    const response = await getUserActionLogs()

    const data = response.data.userActionLogs
    if (data && data.length > 0) {
      const logsForTableWithDataTemp = []

      // loop through all logs
      data.forEach((log) => {
        // only get action logs which are not published
        if (log.isEntryPublished === 1) {
          // parse hours and additional text to yext format
          const hoursAndTextObj = parseUserActionLogChanges(
            log.contentfulEntryName.trim(),
            '-',
            log.id
          )
          // parse emergency banner text to yext format
          const banner = formatEmergencyBanner(
            log.contentfulEntryName.trim(),
            '%',
            log.id
          )

          // push each log into a temp array
          let parsedLog = prepareLogHelper(log)

          // add website name to log object
          parsedLog = {
            ...parsedLog,
            ...{
              websiteName: log.websiteName,
              websiteID: log.websiteID,
              userID: log.userID,
            },
          }

          let complexLog = {}

          // merge hours and additional text changes into common object
          complexLog = {
            ...complexLog,
            ...hoursAndTextObj,
          }

          // merge emergency banner changes into common object
          complexLog = {
            ...complexLog,
            ...banner,
          }

          // merge parsed log data and yextid into common object
          complexLog = {
            ...complexLog,
            ...parsedLog,
          }

          logsForTableWithDataTemp.unshift(complexLog)
        }
      })
      setLogs(logsForTableWithDataTemp)
    }

    setLoadingStatus(false)
  }, [])

  useEffect(() => {
    getActionLogsRest()
  }, [getActionLogsRest])

  const getContentfulLogsRest = useCallback(async () => {
    setLoadingStatus(true)
    const response = await getAllContentfulLogs()
    const data = response.data.getAllContentfulLogs
    setContentfulLogs(data)
    setLogs('')
    setLoadingStatus(false)
  })
  const handleChangeSource = () => {
    switch (source) {
      case 'Contentful':
        setSource('yext')
        getActionLogsRest()
        break
      case 'yext':
        setSource('Contentful')
        getContentfulLogsRest()
        break
      default:
        break
    }
  }

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }

  return !isLoading ? (
    <UpdatedMainLayout>
      <div className="rw-user-action-logs__container py-2">
        <span>
          Showing results from {source}
          <button
            className="w-auto h-40 px-16 text-white bg-teal-600 ml-6"
            onClick={(e) => handleChangeSource()}
          >
            Switch Source
          </button>
        </span>
        {contentfulLogs && (
          <div>
            <MaterialTable
              icons={tableIcons}
              options={{
                search: false,
                filtering: true,
                toolbar: true,
                headerStyle: { backgroundColor: '#c9cbd1' },
                pageSize: 10,
                sorting: true,
                pageSizeOptions: [10, 20, 100, 200],
              }}
              columns={[
                { title: 'Entry ID', field: 'contentfulId' },
                {
                  title: 'Created At',
                  field: 'createdAt',
                  render: (rowData) =>
                    new Date(rowData.createdAt).toLocaleString('en-US'),
                },
                { title: 'By User', field: 'email' },
                { title: 'User ID', field: 'id' },
                {
                  title: 'isPublished',
                  field: 'isPublished',
                  render: (rowData) => (rowData.isPublished ? 'Yes' : 'No'),
                },
              ]}
              data={contentfulLogs}
              title="Staff Logs"
            />
          </div>
        )}
      </div>

      {logs && <UserActionLogs logs={logs} />}
    </UpdatedMainLayout>
  ) : (
    <Fragment>
      <UpdatedMainLayout>
        <PawLoader />
      </UpdatedMainLayout>
    </Fragment>
  )
}

export default UserActionLogsCellRest
