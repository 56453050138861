import React, { Fragment } from 'react'
import MaterialTable from 'material-table'
import UndoIcon from '@material-ui/icons/Undo'
import * as _ from 'lodash'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import HoursTable from './HoursTable'
import AdditionalText from './AdditionalText'
import EmergencyBanner from './EmergencyBanner'
import PawLoader from '../PawLoader'
import HaveExtendedHours from './HaveExtendedHours'
import moment from 'moment'
import { useGlobalState } from '../../hooks/state/state'

const PublishLogTable = ({ undoPublishLog, logs, contentfulLogs }) => {
  /**
   * Remove unpublished publish log entry from the table and db.
   * Update the logs in the table afterwards.
   *
   * @param {Object} rowData table row
   */

  const onUndoPublishLog = async (rowData) => {
    rowData.updateType === 'Emerg Banner'
      ? undoPublishLog(rowData.idpublish_logs, rowData.updateType)
      : undoPublishLog(rowData.idpublish_logs)
  }

  const [descriptionInitial] = useGlobalState('initialBanner')

  return (
    <Fragment>
      {(logs && logs.length > 0) ||
      (contentfulLogs && contentfulLogs.length > 0) ? (
        <div className="rw-changes-tables rw-action-log-table">
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: <></>,
              },
            }}
            options={{
              search: false,
              filtering: false,
              paging: false,
              toolbar: false,
              headerStyle: { backgroundColor: '#c9cbd1' },
              actionsColumnIndex: -1,
              draggable: false,
            }}
            columns={[
              {
                title: 'DATE & TIME',
                field: 'createdAt',
                cellStyle: {
                  width: '250px',
                },
                render: (row) => (
                  <span>
                    {moment(row['createdAt']).format('MM/DD/YYYY hh:mm a')}
                  </span>
                ),
              },
              {
                title: 'UPDATE',
                field: 'updateType',
                cellStyle: {
                  width: '225px',
                },
              },
              {
                title: 'UPDATED BY',
                field: 'userEmail',
                width: 200,
                cellStyle: {
                  width: '310px',
                },
              },
            ]}
            data={logs}
            actions={[
              () => ({
                icon: () => <UndoIcon />,
                tooltip: 'Undo',
                onClick: (event, rowData) => onUndoPublishLog(rowData),
              }),
            ]}
            detailPanel={[
              {
                icon: () => <ArrowForwardIosIcon />,
                render: (rowData) => {
                  return (
                    <Fragment>
                      {(rowData.updateType.includes(', Hours,') ||
                        rowData.updateType.substring(0, 6) == 'Hours,' ||
                        rowData.updateType == 'Hours') && (
                        <HoursTable
                          changes={rowData.savedUpdates.filter(
                            (item) =>
                              item.type == 'Hours' &&
                              (item.hoo_fieldName == 'status' ||
                                item.hoo_fieldName == 'interval')
                          )}
                          title="Hours of Operation"
                        />
                      )}
                      {rowData.updateType.includes('Ext Hours') && (
                        <HoursTable
                          changes={rowData.savedUpdates.filter(
                            (item) =>
                              item.type == 'Ext Hrs' &&
                              (item.hoo_fieldName == 'status' ||
                                item.hoo_fieldName == 'interval')
                          )}
                          title="Extended Hours of Operation"
                        />
                      )}

                      {rowData.updateType.includes('Hours Text') && (
                        <AdditionalText
                          changes={rowData.savedUpdates.filter(
                            (item) =>
                              item.type == 'Hours' &&
                              item.hoo_fieldName == 'text'
                          )}
                          title="Additional Text"
                        />
                      )}

                      {rowData.updateType.includes('Ext Text') && (
                        <AdditionalText
                          changes={rowData.savedUpdates.filter(
                            (item) =>
                              item.type == 'Ext Hrs' &&
                              item.hoo_fieldName == 'text'
                          )}
                          title="Extended Additional Text"
                        />
                      )}

                      {rowData.updateType.includes('Emerg Banner') && (
                        <EmergencyBanner
                          oldEmergencyBanner={rowData.savedUpdates[0].oldValue}
                          modifiedEmergencyBanner={
                            rowData.savedUpdates[0].newValue
                          }
                        />
                      )}

                      {rowData.updateType.includes('Toggle Ext Hrs') && (
                        <HaveExtendedHours
                          changes={rowData.savedUpdates.filter(
                            (item) =>
                              item.type == 'Ext Hrs' &&
                              item.hoo_fieldName == 'toggle'
                          )}
                          title="Enable Extended Hours"
                        />
                      )}
                    </Fragment>
                  )
                },
              },
            ]}
          />
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: <></>,
              },
            }}
            options={{
              search: false,
              filtering: false,
              paging: false,
              toolbar: false,
              header: false,
            }}
            columns={[
              {
                cellStyle: {
                  //paddingLeft: '60px',
                  width: '100px',
                },
              },
              {
                title: 'Created At',
                field: 'createdAt',
                render: (rowData) =>
                  new Date(rowData.createdAt).toLocaleString('en-US'),
                cellStyle: {
                  width: '283px',
                  textTransform: 'lowercase',
                },
              },
              {
                title: 'Entry ID',
                field: 'contentfulId',
                render: (rowData) => {
                  return `${
                    descriptionInitial &&
                    descriptionInitial['contentfulEntry'] &&
                    descriptionInitial['contentfulEntry'] ==
                      rowData.contentfulId
                      ? 'Emergency banner Contentful'
                      : 'Staff'
                  }`
                },
                cellStyle: {
                  width: '290px',
                },
              },

              {
                title: 'By User',
                field: 'email',
                width: 200,
                cellStyle: {
                  width: '300px',
                },
              },
              // {
              //   title: 'Published ?',
              //   field: 'isPublished',
              //   render: (rowData) => (rowData.isPublished ? 'Yes' : 'No'),
              // },
            ]}
            data={contentfulLogs}
          />
        </div>
      ) : logs &&
        logs.length === 0 &&
        contentfulLogs &&
        contentfulLogs.length === 0 ? (
        <div className="rw-modal__no-logs-message">
          <h3>There are currently no logs to display!</h3>
        </div>
      ) : (
        <PawLoader />
      )}
    </Fragment>
  )
}

export default PublishLogTable
