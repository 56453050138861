import React, { useState, useCallback } from 'react'
import GroupCard from './GroupCard'
//import { groupBySiteId } from '../../api/groups'
import { getAllGroups } from '../../api/groups'
import { updateUser } from '../../api/users'
import { siteMessages } from '../../constants/messages/en'
import { useCMS } from 'tinacms'
import ChevronDown from '-!svg-react-loader?name=Icon!../../static/images/chevron-down-solid.svg'
import './EditUserModal.css'

function EditUserModal({ user, showEdit, setIsInfoChanged }) {
  const [siteId, setSiteId] = useState('')
  const [currentGroups, setCurrentGroups] = useState(user.groups)
  const [showError, setShowError] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [selectedRole, setSelectedRole] = useState(user.roleId)
  const [isUserChanged, setIsUserChanged] = useState(false)
  const [userAlreadyHasSite, setUserAlreadyHasSite] = useState(false)

  const cms = useCMS()

  const handleClose = () => {
    showEdit(false)
    if (isUserChanged) setIsInfoChanged(true)
  }

  const handleFocus = () => {
    setSiteId('')
    setShowError(false)
    setUserAlreadyHasSite(false)
  }

  const handleChange = (e) => {
    setSiteId(e.target.value)
  }

  const handleAdd = async (user) => {
    let userGroups = currentGroups.map((group) => group.id)

    const groupsRest = await getAllGroups()
    const { data } = groupsRest

    let group = data.groups.filter((group) => group.siteId == siteId)
    if (group.length == 0) {
      setShowError(true)
    } else {
      // check if group is already present in userGroups
      let groupID = group[0].id

      const resultOfDupesCheck = userGroups.filter(
        (groupNewID) => groupNewID == groupID
      )

      if (resultOfDupesCheck.length > 0) {
        setUserAlreadyHasSite(true)
      } else {
        userGroups.push(group[0].id)

        let date = new Date().toISOString()

        let update = {
          roleId: user.roleId,
          groups: userGroups,
          updatedAt: date,
        }

        try {
          const query = JSON.stringify(update).replace(/"([^"]+)":/g, '$1:')
          await updateUser(user.id, query)

          cms.alerts.info('User Updated')
          let updatedGroups = [...currentGroups, group[0]]
          setCurrentGroups(updatedGroups)
          setSiteId('')
          setIsUserChanged(true)
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  const handleDelete = async (group) => {
    let updateGroups = currentGroups.filter((data) => data.id != group.id)
    let date = new Date().toISOString()
    let userGroups = updateGroups.map((group) => group.id)

    let update = {
      roleId: user.roleId,
      groups: userGroups,
      updatedAt: date,
    }

    try {
      const query = JSON.stringify(update).replace(/"([^"]+)":/g, '$1:')
      await updateUser(user.id, query)
      cms.alerts.info('User Updated')
      setIsUserChanged(true)

      setCurrentGroups(updateGroups)
    } catch (e) {
      console.log(e)
    }
  }

  const confirmSelection = async () => {
    let newRole
    if (selectedRole == 1 && user.roleId != 1) {
      newRole = 1
    } else if (selectedRole === 2 && user.roleId != 2) {
      newRole = 2
    }

    if (newRole) {
      let userGroups = currentGroups.map((group) => group.id)
      let date = new Date().toISOString()
      let update = {
        roleId: newRole,
        groups: userGroups,
        updatedAt: date,
      }

      try {
        const query = JSON.stringify(update).replace(/"([^"]+)":/g, '$1:')
        await updateUser(user.id, query)
        cms.alerts.info('User Updated')
        setShowConfirmation(false)
        setIsUserChanged(true)
      } catch (e) {
        console.log(e)
      }
    }
  }

  let cancelSelection = () => {
    if (selectedRole != user.roleId) {
      setSelectedRole(user.roleId)
    }
    setShowConfirmation(!showConfirmation)
  }

  let handleSelection = (role) => {
    // console.log('handleSelection -> role', role)
    role == 'Admin' ? setSelectedRole(1) : setSelectedRole(2)
    setShowDropdown(!showDropdown)
    setShowConfirmation(true)
  }
  return (
    <>
      <div className="modal-overlay" />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div
          className="user-modal rw-modal w-850"
          style={{
            alignItems: 'center',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '1.75rem auto',
            position: 'relative',
            width: '80%',
            zIndex: '100',
            top: '11rem',
          }}
        >
          <div className="modal-header w-full border-b border-gray-300 flex flex-row justify-start px-8 py-3">
            <div className="w-11/12 flex flex-row pr-4 border-r border-gray-300">
              <span className="font-semibold pr-4 border-r border-gray-300">
                Edit User
              </span>
              <div className="flex flex-row pl-4">
                <span className="mr-6 font-light">{user.nickname}</span>
                <span className="font-light">UPN: {user.email}</span>
                <div className="ml-4">
                  <button
                    className="flex flex-row focus:outline-none"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    {selectedRole == 1
                      ? `Admin`
                      : selectedRole == 2
                      ? 'Editor'
                      : ''}
                    <ChevronDown className="w-6 pl-4 mt-2 text-teal-500" />
                  </button>
                  {showDropdown ? (
                    <div className="absolute flex flex-col pb-6 bg-white shadow-outer pt-2 mt-4 role-dropdown-contents">
                      <div
                        onClick={() => handleSelection('Admin')}
                        className="cursor-pointer hover:bg-teal-100 px-8 py-2"
                      >
                        Admin
                      </div>
                      <div
                        onClick={() => handleSelection('Editor')}
                        className="cursor-pointer hover:bg-teal-100 px-8 py-2"
                      >
                        Editor
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {showConfirmation ? (
                    <div className="absolute flex flex-col pb-6 bg-white shadow-outer pt-2 mt-4 role-dropdown-contents">
                      <span className="px-8 py-2">Are you sure?</span>
                      <div className="flex justify-center">
                        <button
                          onClick={() => cancelSelection()}
                          className="cursor-pointer text-teal-500 underline mx-2 focus:outline-none"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={confirmSelection}
                          className="cursor-pointer text-teal-500 underline mx-2 focus:outline-none"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/12 text-right">
              <button
                type="button"
                className="user-modal-close-button rw-modal-close-button focus:outline-none"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
                style={{
                  fontSize: '1.4rem',
                  fontWeight: '700',
                  lineHeight: '1',
                  cursor: 'pointer',
                  border: 'none',
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div className="px-8 py-6 w-95">
            <span className="text-teal-500 font-light">Add User To A Site</span>
            <div className="flex flex-row pb-6">
              <input
                type="text"
                className="font-light w-full border-b border-gray-700 focus:outline-none"
                value={siteId}
                onChange={handleChange}
                onFocus={handleFocus}
                placeholder="Enter site ID"
              />
              <button
                onClick={() => handleAdd(user)}
                className="w-100 ml-2 mt-4 bg-teal-500 text-white focus:outline-none"
              >
                + Add
              </button>
            </div>
            {showError && (
              <p className="pb-8 text-red-600">
                Sorry, Site Id is not available!
              </p>
            )}
            {userAlreadyHasSite && (
              <p className="pb-8 text-red-600">{siteMessages.SITE_HAS_USER}</p>
            )}
            <span className="p-1 bg-teal-100 font-semibold my-8">
              {currentGroups.length} sites
            </span>
            <div className="pt-6">
              {currentGroups.map((group, index) => {
                return (
                  <GroupCard
                    group={group}
                    handleDelete={handleDelete}
                    key={index}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditUserModal
