/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react'
import { getUserById } from '../../api/users'
import MainLayout from 'src/layouts/MainLayout'
import { useParams, useHistory, Link } from 'react-router-dom'
import WebsitePageMain from '../../pages/WebsitePagePage/WebsitePageMain'
import WebsitePageHoursEditForm from '../../pages/WebsitePagePage/WebsitePageHoursEditForm'
import WebsiteEmergencyBannerEditForm from '../../pages/WebsitePagePage/WebsiteEmergencyBannerEditForm'
import WebsiteStaffEditForm from '../../pages/WebsitePagePage/WebsitePageStaff'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import { client, cma } from '../../../../api/src/lib/contentful'
import * as _ from 'lodash'

import {
  setInitialBannerMsg,
  setInitialHours,
  setUpdatedHours,
  setIsHoursUpdated,
  setIsBannerUpdated,
  setUpdateBannerMsg,
  setSelectedSite,
  setYextId,
  setYextResponse,
  setMultiLocations,
  setCurrentYextId,
  setStaffPages,
  useGlobalState,
  setShowBannerState,
  setBannerBackgroundColor,
  setWebsiteTheme,
  setReRenderStaff,
} from 'src/hooks/state/state'
import PawLoader from '../PawLoader'
import {
  getLatestEBUpdate,
  getLatestHOOUpdate,
  getLatestHoursUpdates,
} from 'src/api/publishLogs'

const ValidateUserGroupCellRest = ({ routingInfo, id }) => {
  let { siteId, pageName } = useParams()
  let history = useHistory()
  const [user, setUser] = useState([])
  const [isLoading, setLoadingStatus] = useState(true)
  const [initialHours] = useGlobalState('initialHours')
  const [initialBannerMsg] = useGlobalState('initialBanner')
  const [yextId] = useGlobalState('yextIdObj')
  const [reRenderStaff] = useGlobalState('reRenderStaff')

  let selectedSite =
    routingInfo && routingInfo.location && routingInfo.location.state

  let view = 'large'

  if (selectedSite) {
    view = selectedSite.view ? selectedSite.view : 'large'
  }

  // get yext data
  const getYextData = async (yextId) => {
    return await fetch(
      `${process.env.EE_REST_YEXT_ENDPOINT}?yextID=${yextId}`,
      {
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
        },
      }
    ).then((response) => {
      if (response.ok) return response.json()
    })
  }

  //building hours object

  let buildHours = async (yextData, userObj, yextId) => {
    const initialHoursContent = {
      hours: JSON.parse(JSON.stringify(yextData.response.hours)),
      additionalHoursText: yextData.response.additionalHoursText,
      c_extendedHours: yextData.response.c_extendedHours
        ? JSON.parse(JSON.stringify(yextData.response.c_extendedHours))
        : {},
      c_extendedAdditionalHoursText:
        yextData.response.c_extendedAdditionalHoursText,
    }

    const hooResponse = await getLatestHoursUpdates(userObj.id, siteId, yextId)
    const toggleResponse = await getLatestHOOUpdate(
      'Ext Hrs',
      'toggle',
      null,
      userObj.id,
      siteId,
      yextId.toString()
    )
    //console.log('buildHours -> toggleResponse', toggleResponse)
    const haveExtendedHours =
      toggleResponse.data.latestHOOUpdate &&
      toggleResponse.data.latestHOOUpdate.length > 0
        ? toggleResponse.data.latestHOOUpdate[0].newValue == 'false'
          ? false
          : true
        : yextData.response.c_extendedHours
        ? true
        : false

    if (
      hooResponse.data.latestHoursUpdates != null &&
      hooResponse.data.latestHoursUpdates.length > 0
    ) {
      hooResponse.data.latestHoursUpdates.forEach((item) => {
        if (item.type == 'Hours') {
          switch (item.hoo_fieldName) {
            case 'interval': {
              let intervals = []

              item.openIntervals.map((interval) =>
                intervals.push({
                  start: interval.newStart,
                  end: interval.newEnd,
                })
              )

              initialHoursContent.hours[`${item.hoo_day}`]
                ? !initialHoursContent.hours[`${item.hoo_day}`].isClosed
                  ? (initialHoursContent.hours[
                      `${item.hoo_day}`
                    ].openIntervals = intervals)
                  : ''
                : (initialHoursContent.hours[`${item.hoo_day}`] = {
                    openIntervals: intervals,
                  })

              // initialHoursContent.hours[`${item.hoo_day}`] = {
              //   openIntervals: intervals,
              // }
              break
            }
            case 'status':
              initialHoursContent.hours[`${item.hoo_day}`]
                ? item.newValue == 'Closed'
                  ? (initialHoursContent.hours[`${item.hoo_day}`] = {
                      isClosed: item.newValue == 'Open' ? false : true,
                    })
                  : (initialHoursContent.hours[`${item.hoo_day}`].isClosed =
                      item.newValue == 'Open' ? false : true)
                : (initialHoursContent.hours[`${item.hoo_day}`] = {
                    isClosed: item.newValue == 'Open' ? false : true,
                  })

              // initialHoursContent.hours[`${item.hoo_day}`].isClosed =
              //   item.newValue == 'Closed' ? true : false
              //delete initialHoursContent.hours[`${item.hoo_day}`].openIntervals
              break
            case 'text':
              initialHoursContent.additionalHoursText = item.newValue
              break
            default:
              break
          }
        } else if (item.type == 'Ext Hrs' && haveExtendedHours == 'true') {
          switch (item.hoo_fieldName) {
            case 'interval': {
              let intervals = []

              item.openIntervals.map((interval) =>
                intervals.push({
                  start: interval.newStart,
                  end: interval.newEnd,
                })
              )

              initialHoursContent.c_extendedHours[`${item.hoo_day}`]
                ? !initialHoursContent.c_extendedHours[`${item.hoo_day}`]
                    .isClosed
                  ? (initialHoursContent.c_extendedHours[
                      `${item.hoo_day}`
                    ].openIntervals = intervals)
                  : ''
                : (initialHoursContent.c_extendedHours[`${item.hoo_day}`] = {
                    openIntervals: intervals,
                  })

              // initialHoursContent.c_extendedHours[
              //   `${item.hoo_day}`
              // ].openIntervals = intervals

              break
            }
            case 'status':
              initialHoursContent.c_extendedHours[`${item.hoo_day}`]
                ? item.newValue == 'Closed'
                  ? (initialHoursContent.c_extendedHours[`${item.hoo_day}`] = {
                      isClosed: item.newValue == 'Open' ? false : true,
                    })
                  : (initialHoursContent.c_extendedHours[
                      `${item.hoo_day}`
                    ].isClosed = item.newValue == 'Open' ? false : true)
                : (initialHoursContent.c_extendedHours[`${item.hoo_day}`] = {
                    isClosed: item.newValue == 'Open' ? false : true,
                  })
              // initialHoursContent.c_extendedHours[`${item.hoo_day}`].isClosed =
              //   item.newValue == 'Closed' ? true : false

              // delete initialHoursContent.c_extendedHours[`${item.hoo_day}`]
              //   .openIntervals
              break
            case 'text':
              initialHoursContent.c_extendedAdditionalHoursText = item.newValue
              break
            default:
              break
          }
        }
      })

      if (!haveExtendedHours) {
        initialHoursContent.c_extendedHours = {}
      }
    }

    return initialHoursContent
  }

  let buildEbObj = async (yextData, userObj) => {
    let bannerText = formatBannerText(
      yextData.response.c_websiteEmergencyBanner
    )
    const initialBannerContent = {
      banner: bannerText,
    }

    const ebResponse = await getLatestEBUpdate(userObj.id, siteId)
    if (
      ebResponse.data.latestEBUpdate != null &&
      ebResponse.data.latestEBUpdate.length > 0
    ) {
      bannerText = formatBannerText(ebResponse.data.latestEBUpdate[0].newValue)
      initialBannerContent.banner = bannerText
    }

    return initialBannerContent
  }

  /**
   * Get a user and yext data.
   */
  const getUserRestAndYextData = useCallback(
    async (userObj) => {
      try {
        // get yextId from user object based on passed siteId
        const foundYextId = userObj.groups.filter(
          (group) => siteId == group.siteId
        )

        let multilocationObj = foundYextId[0].multiLocationObject
          ? foundYextId[0].multiLocationObject.split('&')
          : null

        if (foundYextId.length > 0) {
          setYextId(foundYextId)
          setCurrentYextId(foundYextId[0].yextId.toString())
          let hackyYextId = null
          // ! - this is a TEMPORARY HACK - if an id is less than 1000, concat an extra 0 in front
          // ! because mysql doesnt allow to attach an extra 0 in front of ints
          if (foundYextId[0].yextId.toString().length < 4) {
            hackyYextId = `0${foundYextId[0].yextId}`
          }

          const yextData = await getYextData(
            hackyYextId != null ? hackyYextId : foundYextId[0].yextId
          )

          setYextResponse(JSON.parse(JSON.stringify(yextData.response)))

          //building mainhours Object
          let initialHoursContent = await buildHours(
            yextData,
            userObj,
            foundYextId[0].yextId
          )
          // console.log(
          //   'getUserRestAndYextData -> initialHoursContent',
          //   initialHoursContent
          // )

          //building EB object
          let initialBannerContent = await buildEbObj(yextData, userObj)

          //building multi location object

          if (multilocationObj && multilocationObj.length > 0) {
            let locationsObj = await Promise.all(
              multilocationObj.map(async (location) => {
                location = JSON.parse(location)

                const yextData = await getYextData(
                  location.yextId.length < 4
                    ? `0${location.yextId}`
                    : location.yextId
                )
                let hoursObj = await buildHours(
                  yextData,
                  userObj,
                  location.yextId
                )

                return {
                  url: location.url,
                  name: location.name,
                  yextId: location.yextId,
                  hours: hoursObj,
                }
              })
            )
            setMultiLocations(locationsObj)
          } else {
            setMultiLocations([])
          }

          setInitialHours(initialHoursContent)

          setUpdatedHours({})
          setIsHoursUpdated(false)
          setIsBannerUpdated(false)
          setUpdateBannerMsg('')
          setInitialBannerMsg('text', initialBannerContent.banner)
        }

        setLoadingStatus(false)
      } catch (error) {
        throw new Error(error)
      }
    },
    [id, siteId]
  )

  const formatBannerText = (text) => {
    return typeof text !== 'undefined' ? text.replace(/\\/g, '') : ''
  }

  const getStaffDataAndEB = useCallback(async () => {
    try {
      const data = await client.getEntries({
        content_type: 'site',
        'fields.siteId': siteId,
        include: 5,
      })

      if (data.includes.Entry) {
        let staffPages = []
        data.includes.Entry.map((item) => {
          if (item.fields.pageType && item.fields.pageType === 'Staff') {
            //console.log('staff: ', item)
            staffPages.push(item)
          }
        })
        setStaffPages(staffPages)
      }
      setReRenderStaff(false)

      if (
        data &&
        data.items &&
        data.items[0] &&
        data.items[0].fields &&
        data.items[0].sys
      ) {
        setShowBannerState(
          data.items[0].fields.showEmergencyBanner == false ? false : true
        )
        setBannerBackgroundColor(
          data.items[0].fields.emergencyBannerBackgroundColour || 'Red'
        )
        setWebsiteTheme(data.items[0].fields.theme || undefined)
        setInitialBannerMsg('contentfulEntry', data.items[0].sys.id)
      }
    } catch (err) {
      console.log(err)
    }
  }, [id, siteId])

  useEffect(() => {
    let performaActions = async () => {
      // get user
      const usersRest = await getUserById({ id })
      const { data } = usersRest
      const userObj = data.userByID

      setUser(userObj)
      getUserRestAndYextData(userObj)
      //getStaffData(userObj)
    }

    if (typeof id !== 'undefined') {
      performaActions()
    }
  }, [getUserRestAndYextData, id])

  useEffect(() => {
    if (reRenderStaff) getStaffDataAndEB()
  })

  setSelectedSite(selectedSite ? selectedSite : yextId[0])

  return !isLoading ? (
    yextId && yextId.length > 0 && !_.isEmpty(initialHours) ? (
      <MainLayout groups={user.groups} userId={user.id}>
        {pageName &&
        (pageName == 'hours' || pageName == 'multi-location-hours') ? (
          <WebsitePageHoursEditForm
            routingInfo={routingInfo}
            view={view}
            user={user}
          />
        ) : pageName && pageName == 'about' ? (
          <WebsiteEmergencyBannerEditForm
            routingInfo={routingInfo}
            view={view}
            user={user}
            key={initialBannerMsg.key}
          />
        ) : pageName &&
          (pageName == 'staff' || pageName == 'multi-location-staff') ? (
          <WebsiteStaffEditForm routingInfo={routingInfo} user={user} />
        ) : pageName && pageName == 'main' ? (
          <WebsitePageMain routingInfo={routingInfo} view={view} user={user} />
        ) : (
          history.goBack()
        )}
      </MainLayout>
    ) : (
      <UpdatedMainLayout>
        <span className="pl-6">
          Sorry you do not have access to this site. Please go back to{' '}
          <Link to="/dashboard" className="underline text-teal-600">
            Dashboard
          </Link>
        </span>
      </UpdatedMainLayout>
    )
  ) : (
    <UpdatedMainLayout>
      <PawLoader />
    </UpdatedMainLayout>
  )
}

export default ValidateUserGroupCellRest
