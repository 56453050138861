import moment from 'moment'

/**
 * Parse logs into a readable format for table to display.
 * @param {array`} data logs
 */
export const prepareLogHelper = (data) => {
  const { contentfulEntryName, createdAt, userEmail } = data

  // look through update string to see what the user is updating
  const regex = /(?<=([$%]))(?:hours|additionalText|extendedHours|newmsg|newExtendedmsg|modifiedEmergencyBanner|haveExtendedHours)/gi

  // return array of updated fields
  const typesOfUpdatesArray = contentfulEntryName.match(regex)

  // convert array to set to eliminate dups
  const typesOfUpdatesSet = new Set(typesOfUpdatesArray)

  // convert to string and modify updates to human readable text
  const update = Array.from(typesOfUpdatesSet)
    .map((update) => {
      if (update === 'hours') return 'Hours'
      else if (update === 'newmsg') return 'Text'
      else if (update === 'extendedHours') return 'Ext. Hours'
      else if (update === 'newExtendedmsg') return 'Ext. Text'
      else if (update === 'modifiedEmergencyBanner') return 'Emergency Banner'
      else if (update === 'haveExtendedHours') return 'On/Off Ext. Hrs'
    })
    .join(', ')

  // format the date
  let formattedDate = ''
  if (createdAt) {
    formattedDate = moment.utc(createdAt).local().format('MM/DD/YYYY h:mm a')
  }

  // construct table row
  return {
    date: formattedDate,
    update,
    email: userEmail,
    id: data.id,
  }
}
