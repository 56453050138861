import LoaderIcon from '../../static/images/pawLoader.svg'

const PawLoader = () => {
  return (
    <div className="flex w-full px-6 items-center">
      <div className="inline-block  h-14 w-14 mr-4">
        <LoaderIcon />
      </div>
      Please wait while we are fetching the information...
    </div>
  )
}

export default PawLoader
