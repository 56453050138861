import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { getAllUsers } from '../../api/users'
import Users from 'src/components/Users'
import MainLayout from '../../layouts/MainLayout'
import PawLoader from '../PawLoader'

const UsersCellRest = () => {
  const [users, setUsers] = useState([])
  const [isLoading, setLoadingStatus] = useState(true)
  const [isInfoChanged, setIsInfoChanged] = useState(false)

  /**
   * Get a user by email from REST API.
   */
  const getUsersRest = useCallback(async () => {
    // get all users
    const response = await getAllUsers()
    let restUsers = response.data.users

    setUsers(restUsers)
    setLoadingStatus(false)
    setIsInfoChanged(false)
  }, [])

  useEffect(() => {
    getUsersRest()
  }, [getUsersRest, isInfoChanged])

  return !isLoading ? (
    <Users users={users} setIsInfoChanged={setIsInfoChanged} />
  ) : (
    <Fragment>
      <PawLoader />
    </Fragment>
  )
}

export default UsersCellRest
