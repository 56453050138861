import React, { Fragment, useState } from 'react'
import ReactDOM from 'react-dom'
// import UserActionLogsByUserCell from 'src/components/UserActionLogsByUserCell'
import { msalConfig } from '../../../auth/authConfig'
import * as Msal from 'msal'
import UserActionLogsByUserCellRest from 'src/components/UserActionLogsByUserCellRest'
import CatImg from '../../static/images/cute-cat.png'

import './PublishModal.css'

const PublishModal = ({
  savingPublishedChanges,
  isSaving,
  isShowing,
  hide,
  user,
}) => {
  const authClient = new Msal.UserAgentApplication(msalConfig)
  const account = authClient.getAccount()

  const resetPublishButton = () => {
    savingPublishedChanges(false)
    hide()
  }

  return isShowing
    ? ReactDOM.createPortal(
        <Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div
                className="modal-header"
                style={{
                  alignSelf: 'flex-start',
                  paddingTop: '40px',
                  color: '#fff',
                  fontSize: '30px',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {!isSaving && <h1>Publish your website</h1>}
                {isSaving && <h1>Your website is being published</h1>}
                <button
                  type="button"
                  className="modal-close-button rw-modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={resetPublishButton}
                  style={{
                    fontSize: '1.8rem',
                    fontWeight: '400',
                    lineHeight: '1',
                    cursor: 'pointer',
                    border: 'none',
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {!isSaving && (
                <div className="rw-modal-body">
                  {account && account.userName && (
                    <UserActionLogsByUserCellRest
                      email={account.userName}
                      user={user}
                    />
                  )}
                </div>
              )}
              {isSaving && (
                <div className="rw-modal-body py-8 pr-6 pl-4 flex-row font-light text-lg">
                  <div className="w-3/12 mr-10">
                    <img
                      src={CatImg}
                      alt="cute cartoon cat thumbs up"
                      width="240"
                    />
                  </div>
                  <div className="w-2/3">
                    <p className="leading-relaxed pt-3 font-black subheading-text">
                      It may take up to 15 minutes for your changes to appear on
                      your website.
                    </p>
                    <p className="leading-relaxed pb-3">
                      You will receive an email when the publish is complete. If
                      you do not see your changes, please follow the
                      instructions in the email.
                    </p>
                    <p className="leading-relaxed py-3">
                      You will not be able to make additional changes to your
                      website until the previous publish is complete. If you
                      have any questions, please visit the{' '}
                      <a
                        href="https://www.gpconnect.nva.com/easy-editor-faqs"
                        className="text-teal-600"
                        target="_blank"
                      >
                        FAQ on GP Connect
                      </a>{' '}
                      or send us a message at{' '}
                      <a
                        href="mailto:marketing@nva.com"
                        className="text-teal-600"
                      >
                        marketing@nva.com
                      </a>
                      . <br />
                      Please make sure to include your location name and ID.
                      We'll get back to you as soon as we can during normal
                      business hours.
                    </p>
                    <p className="leading-relaxed py-3">
                      Thank you! <br />
                      NVA Marketing Knowledge and Success Team
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fragment>,
        document.body
      )
    : null
}

export default PublishModal
