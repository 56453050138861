import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import DesktopMacIcon from '@material-ui/icons/DesktopMac'

const DesktopMobileToggle = ({ ...props }) => (
  <div className="flex flex-row justify-end my-auto ml-0 mr-6 view-buttons z-10">
    <button
      onClick={() => {
        props.setIframeView('large')
        props.sendOnLoadMsg()
      }}
      className={`${
        props.iframeView == 'large' ? 'active-view-button' : ''
      } p-2 focus:outline-none`}
    >
      <DesktopMacIcon
        style={{
          color: `${props.iframeView == 'large' ? 'white' : '#0694a2'}`,
        }}
      />
    </button>
    <button
      onClick={() => {
        props.setIframeView('mobile')
        props.sendOnLoadMsg()
      }}
      className={`${
        props.iframeView == 'mobile' ? 'active-view-button' : ''
      } p-2 focus:outline-none`}
    >
      <PhoneIphoneIcon
        style={{
          color: `${props.iframeView == 'mobile' ? 'white' : '#0694a2'}`,
        }}
      />
    </button>
  </div>
)

export default DesktopMobileToggle
