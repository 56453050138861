import react, { useState, useEffect, useCallback } from 'react'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import { getConstantByKey, updateConstant } from '../../api/publishLogs'
const SettingsPage = () => {
  const [eeEmergencyBanner, setEeEmergencyBanner] = useState([])
  const [saving, setSaving] = useState(false)

  const getConstants = useCallback(async () => {
    const response = await getConstantByKey('ee_emergency_banner')
    let ConstantByKey = response.data.getConstantByKey;
    setEeEmergencyBanner(ConstantByKey[0].value)
  }, [])

  useEffect(() => {
    getConstants()
  }, [])

  const saveEmBanner = async () => {
    setSaving(true)
    const data = { key: 'ee_emergency_banner', value: eeEmergencyBanner }
    await updateConstant(data)
      .then(() => {
        setSaving(false)
        document.location.reload();
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const handleInputChange = e => {
    setEeEmergencyBanner(e.target.value)
  }

  const handleInputKeyUp = e => {
    // If enter key is pressed, save banner
    if (e.keyCode === 13) saveEmBanner();
  }

  return (
    <UpdatedMainLayout>
      <div className="container px-6 mx-auto grid">
        <h2 className="text-2xl pb-10 font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Easy Editor Settings
        </h2>
        <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
          <span className="text-gray-700 dark:text-gray-400">
            Global Emergency Banner
          </span>
          <label className="block text-sm">
            <div className="relative">
              <input
                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-teal-400 focus:outline-none focus:shadow-outline-teal dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                placeholder="Emergency Banner Text"
                value={eeEmergencyBanner}
                onChange={(e) => handleInputChange(e)}
                onKeyUp={(e) => handleInputKeyUp(e)}
                style={{ paddingRight: '70px', textOverflow: 'ellipsis' }}
              />

              <button
                className="absolute inline-flex items-center inset-y-0 right-0 px-4 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-teal-600 border border-transparent rounded-r-md active:bg-teal-600 hover:bg-teal-700 focus:outline-none focus:shadow-outline-teal"
                onClick={() => saveEmBanner()}
              >
                {saving && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {saving ? 'Saving' : 'Save'}
              </button>
            </div>
            <span className="helpText ml-2 text-xs text-gray-400">
              Deleting the text will remove the banner completely.
            </span>
          </label>
        </div>
      </div>
    </UpdatedMainLayout>
  )
}

export default SettingsPage
