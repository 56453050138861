import { request } from '../utils/request'

/**
 * Groups REST queries
 */
const queries = {
  allGroups: '{groups {id, name, yextId, siteId, siteURL}}',
  groupsByUserId: '{id, name, yextId, siteURL, siteId }',
}

/**
 * Get all Groups.
 */
export const getAllGroups = async () => await request('get', queries.allGroups)

/**
 * Get groups related to user by id.
 * @param {string} id user's id
 */
export const getGroupsByUserId = async ({ id }) => {
  return await request(
    'get',
    `{groupsByUserId (id: ${id})${queries.groupsByUserId}}`
  )
}
