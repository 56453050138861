import { createGlobalState } from 'react-hooks-global-state'

const { setGlobalState, useGlobalState } = createGlobalState({
  initialBanner: {
    text: '',
    contentfulEntry: null,
  },
  updatedBanner: '',
  isShowBanner: false,
  bannerBackgroundColor: 'Red',
  websiteTheme: undefined,
  isBannerUpdated: false,
  isHoursUpdated: false,
  initialHours: {},
  updatedHours: {},
  selectedSite: {},
  updatedSelectedSite: {},
  yextIdObj: [],
  isSelectedSiteUpdated: false,
  isCurrentUserAdmin: false,
  isHoursDeleted: false,
  isEBDeleted: false,
  yextResponse: {},
  mainSiteHours: {},
  multiLocations: [],
  staffPages: [],
  currentYextId: null,
  currentStaffId: null,
  currentStaffInfo: {},
  reRenderStaff: true,
  isUnderMaintenance: false,
  headerBottomCoordinate: null,
})

// setGlobalState({
//   initialBanner: { text: initialBannerContent.banner, key: null },
//   selectedSiteObj: selectedSite ? selectedSite : yextId[0],
// })

export const setInitialBannerMsg = (key, value) => {
  setGlobalState('initialBanner', (v) => {
    return {
      ...v,
      [key]: value,
    }
  })
}

export const setUpdateBannerMsg = (v) => {
  setGlobalState('updatedBanner', v)
}

export const setShowBannerState = (v) => {
  setGlobalState('isShowBanner', v)
}

export const setBannerBackgroundColor = (v) => {
  setGlobalState('bannerBackgroundColor', v)
}

export const setWebsiteTheme = (v) => {
  setGlobalState('websiteTheme', v)
}

export const setIsBannerUpdated = (v) => {
  setGlobalState('isBannerUpdated', v)
}

export const setInitialHours = (hours) => {
  setGlobalState('initialHours', { ...hours })
}

export const setIsHoursUpdated = (v) => {
  setGlobalState('isHoursUpdated', v)
}

export const setUpdatedHours = (hours) => {
  setGlobalState('updatedHours', { ...hours })
}

export const setisCurrentUserAdmin = (v) => {
  setGlobalState('isCurrentUserAdmin', v)
}
// export const setSelectedSite = ({ siteId, name, siteUrl, yextId }, key) => {
//   setGlobalState('selectedSiteObj', (v) => ({
//     ...v,
//     siteId,
//     name,
//     siteUrl,
//     yextId,
//     key,
//   }))
// }

export const setUpdatedSelectedSite = (v) => {
  setGlobalState('updatedSelectedSite', { ...v })
}

export const setSelectedSite = (v) => {
  setGlobalState('selectedSite', { ...v })
}

export const setYextId = (v) => {
  setGlobalState('yextIdObj', [...v])
}

export const setIsSelectedSiteUpdated = (v) => {
  setGlobalState('isSelectedSiteUpdated', v)
}

export const setIsHoursDeleted = (v) => {
  setGlobalState('isHoursDeleted', v)
}

export const setIsEBDeleted = (v) => {
  setGlobalState('isEBDeleted', v)
}

export const setYextResponse = (v) => {
  setGlobalState('yextResponse', v)
}

export const setMainSiteHours = (v) => {
  setGlobalState('mainSiteHours', { ...v })
}

export const setMultiLocations = (v) => {
  setGlobalState('multiLocations', [...v])
}

export const setStaffPages = (v) => {
  setGlobalState('staffPages', [...v])
}

export const setCurrentYextId = (v) => {
  setGlobalState('currentYextId', v)
}

export const setCurrentStaffId = (v) => {
  setGlobalState('currentStaffId', v)
}

export const setCurrentStaffInfo = (v) => {
  setGlobalState('currentStaffInfo', { ...v })
}

export const setReRenderStaff = (v) => {
  setGlobalState('reRenderStaff', v)
}

export const setIsUnderMaintenance = (v) => {
  setGlobalState('isUnderMaintenance', v)
}

export const setHeaderBottomCoordinate = (v) => {
  setGlobalState('headerBottomCoordinate', v)
}

export { setGlobalState, useGlobalState }
