// styles
import styled from 'styled-components'
import tw from 'twin.macro'

export const ApplyToAll = styled.span`
  font-family: 'open-sans-regular';
  ${tw`float-right py-2 underline text-seaweed`};
`
export const Day = styled.label`
  font-family: 'open-sans-regular';
  ${tw`text-sm font-normal`};
`
export const Tuesday = styled(Day)`
  padding-right: 2px;
`
export const Thursday = styled(Day)`
  padding-right: 2px;
`
export const Saturday = styled(Day)`
  padding-right: 1px;
`

export const Input = styled.input`
  font-family: 'open-sans-regular';
  ${tw`text-sm font-normal text-center placeholder-black border border-black w-80 h-30`};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: grey;
  }
  :-ms-input-placeholder {
    color: grey;
  }
`
export const Label = styled.label`
  font-family: 'open-sans-regular';
  ${tw`px-2 text-xs font-normal`};
`
export const Paragraph = styled.p`
  font-family: 'open-sans-regular';
  white-space: normal;
  ${tw`py-1 text-sm font-normal`};
`
export const HeadingText = styled.h2`
  font-family: 'GoudyOldStyleBold';
  white-space: normal;
  ${tw`text-teal-600 text-3xl`};
`
export const SectionLink = styled.div`
  width: 50%;
  background: #047481;
  border-radius: 0px;
  font-size: 0.9em;
  ${tw`text-white mt-4 mb-6`};
`
export const Select = styled.select`
  font-family: 'open-sans-regular';
  ${tw`text-sm border border-black w-80 h-30`};
`
export const MondaySelect = styled(Select)`
  margin-left: 9px;
`
export const TuesdaySelect = styled(Select)`
  margin-left: 7px;
`
export const WednesdaySelect = styled(Select)`
  margin-left: 10px;
`
export const ThursdaySelect = styled(Select)`
  margin-left: 3px;
`
export const FridaySelect = styled(Select)`
  margin-left: 25px;
`
export const SaturdaySelect = styled(Select)`
  margin-left: 19px;
`
export const SundaySelect = styled(Select)`
  margin-left: 15px;
`
export const Table = styled.table`
  ${tw`pb-3 border-collapse border-separate`}
`
export const AdditionalHours = styled(Day)`
  ${tw`pb-4`}
`
export const TextArea = styled.textarea`
  height: 150px;
  width: 21rem;
  ${tw`mt-2 text-sm border border-black`};
`
