import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import MainLayout from '../../layouts/MainLayout'

import FilterForm from '../UserGroupsCell/FilterForm/FilterForm'
import Grid from '../UserGroupsCell/Grid/Grid'
import SearchBox from '../UserGroupsCell/SearchBox/SearchBox'
import {
  ActionsBar,
  GridRow,
  UserGroupsCell,
} from '../UserGroupsCell/UserGroupCell.styles'
import { getUserById } from '../../api/users'
import PawLoader from '../PawLoader'
import ReactGA from 'react-ga'
import { setReRenderStaff } from 'src/hooks/state/state'

const UserGroupsCellRest = ({ imgSrc, id }) => {
  const [user, setUser] = useState([])
  console.log('UserGroupsCellRest -> user', user)
  const [isLoading, setLoadingStatus] = useState(true)
  const [searchString, setSearchString] = useState('')
  const [sortSelection, setSortSelection] = useState('')
  const history = useHistory()
  setReRenderStaff(true)

  /**
   * Get a user by email from REST API.
   */
  const getUserRest = useCallback(async () => {
    let userObj = {}
    // get user
    const usersRest = await getUserById({ id })
    const { data } = usersRest
    userObj = data.userByID

    setUser(userObj)
    setLoadingStatus(false)
  }, [id])

  useEffect(() => {
    if (typeof id !== 'undefined') getUserRest()
  }, [getUserRest, id])

  const handleSelection = (e) => {
    setSortSelection(e.target.value)
  }

  if (user?.groups?.length == 1) {
    //single site, redirect to main
    ReactGA.event({
      category: 'Navigation',
      action: `${user.name} redirected to a single website`,
      label: `siteID: ${user.groups[0].siteId}`,
    })
    history.push(
      `/site/${user.groups[0].siteId}/website-page/main/${user.groups[0].name}`
    )
  } else {
    user &&
      user.groups &&
      ReactGA.event({
        category: 'Navigation',
        action: `${user.name} displayed with a dashboard contained ${user.groups.length} sites`,
        label: `siteIDs: ${user?.groups?.map((site) => site.siteId)}`,
      })
  }

  return !isLoading ? (
    user.groups.length > 0 ? (
      <UserGroupsCell>
        <ActionsBar>
          <SearchBox setSearchString={setSearchString} />
          <div className="col-6 text-right">
            <select
              onChange={handleSelection}
              className="bg-transparent font-light border-b border-gray-700 rounded-none focus:outline-none"
            >
              <option disabled selected>
                Sort
              </option>
              <option>Name</option>
              <option>Location</option>
            </select>
          </div>
        </ActionsBar>
        <GridRow>
          <Grid
            groups={user.groups}
            searchString={searchString}
            sortSelection={sortSelection}
            imgSrc={imgSrc}
          />
        </GridRow>
      </UserGroupsCell>
    ) : (
        <UserGroupsCell>
          Please{' '}
          <Link className="text-teal-500" to={'/contact'}>
            contact support
        </Link>{' '}
        in order to edit websites
        </UserGroupsCell>
      )
  ) : (
      <PawLoader />
    )
}

export default UserGroupsCellRest
