// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: `${process.env.CLIENT_ID}`,
    authority: `${process.env.CLIENT_AUTHORITY}`,
    redirectUri: process.env.AUTH_URL,
    // redirectUri: 'http://localhost:8910',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphMailEndpoint: 'https://graph.microsoft.com/v1.0/me/messages',
}

// Add here scopes for access token to be used at MS Graph API endpoints.
export const tokenRequest = {
  scopes: ['Mail.Read'],
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
}
