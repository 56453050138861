import * as Msal from 'msal'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams, Link, useLocation } from 'react-router-dom'
import { msalConfig } from '../../../auth/authConfig'
import { getUserByEmail } from '../../api/users'
import SupportDropDown from '../SupportDropDown/SupportDropDown'
import { ModalContext } from '../../context/ModalContext'
import { Context } from '../../../Store'
// import NVALogo from '../../static/images/logo-colour.png'
import NVALogo from '../../static/images/logo-white.png'
import Modal from '../PublishModal'
import './Header.css'
import LaunchIcon from '@material-ui/icons/Launch'
import UserDropDown from '../../components/UpdatedHeader/Navbar/UserDropDown'
import ChevronDown from '-!svg-react-loader?name=Icon!../../static/images/chevron-down-solid.svg'
import ArrowLeft from '-!svg-react-loader?name=Icon!../../static/images/arrow.svg'
import { NavList, NavMenu } from '../UpdatedHeader/UpdatedHeader.styles'
import NavItem from '../UpdatedHeader/NavItem/NavItem'

import { useGlobalState, setisCurrentUserAdmin, setHeaderBottomCoordinate } from '../../hooks/state/state'

const Header = ({ groups, userId }, props) => {
  //const { isAuthenticated, logIn, logOut, userMetadata, loading } = useAuth()

  const { isSaving, isShowing, savingPublishedChanges, toggle } = useContext(
    ModalContext
  )

  const [showDropdown, setShowDropdown] = useState(false)
  const [user, setUser] = useState(null)
  const [emergencyBannerBottomCoordinate, setEmergencyBannerBottomCoordinate] = useState(null)
  const [headerBottomCoordinate] = useGlobalState('headerBottomCoordinate');

  let { siteId, pageName } = useParams()
  let history = useHistory()
  let location = useLocation()

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedSite] = useGlobalState('selectedSite')
  const [isCurrentUserAdmin] = useGlobalState('isCurrentUserAdmin')
  const [isUnderMaintenance] = useGlobalState('isUnderMaintenance')
  const baseSiteURL = selectedSite.siteURL.replace(/#.*$/, '')
  const myMSALObj = new Msal.UserAgentApplication(msalConfig)
  const account = myMSALObj.getAccount()
  // function signOut() {
  //   myMSALObj.logout()
  //   navigate(routes.home())
  // }

  // getting the user role
  useEffect(() => {
    async function getUserRole() {
      const foundRecentlyCreatedUser = await getUserByEmail({
        email: account.userName,
      })
      let { data } = foundRecentlyCreatedUser
      setUser(data.userByEmail[0])
    }
    getUserRole()
  }, [])

  //setting to to sidebar

  useEffect(() => {
    let sideBar = document.querySelector('div[class^="SidebarContainer"]')
    let sideBarWrapper = document.querySelector('div[class^="SidebarWrapper"]')
    let header = document.querySelector('.header-top')

    header && setHeaderBottomCoordinate(header.getBoundingClientRect().bottom);

    let emergencyBanner = document.getElementById('emergency-banner-easyeditor')
    emergencyBanner && setEmergencyBannerBottomCoordinate(emergencyBanner.getBoundingClientRect().bottom)
    // reset header bottom coordinate upon resize
    window.addEventListener('resize', () => {
      header && setHeaderBottomCoordinate(header.getBoundingClientRect().bottom);
      emergencyBanner && setEmergencyBannerBottomCoordinate(emergencyBanner.getBoundingClientRect().bottom)
    })

    if (isUnderMaintenance) {
      let emerElem = document.querySelector('#emergency-banner-easyeditor')
      if (emerElem) {
        emerElem.style.cssText += 'position: fixed'
      }
    } else {
      header.style.cssText = 'top: unset'
    }
    setisCurrentUserAdmin(user && user.roleId && user.roleId == 1)
    if (sideBar) {
      let sidebarHeight = `calc(100vh - ${headerBottomCoordinate}px)`
      let sideBarWrapperHeight = `calc(100vh - ${headerBottomCoordinate}px)`

      sideBar.style.cssText += `top: ${headerBottomCoordinate}px !important; height: ${sidebarHeight} !important;`
      sideBarWrapper.style.cssText += `height: ${sideBarWrapperHeight};`
    }
    // isCurrentUserAdmin
    //   ? sideBar
    //     ? ((sideBar.style.cssText += `top: ${
    //         136 + (isUnderMaintenance ? 50 : 0)
    //       }px !important`),
    //       (sideBarWrapper.style.cssText +=
    //         'height: 92%; padding-bottom: 80px;'))
    //     : ''
    //   : sideBar
    //   ? ((sideBar.style.cssText += `top: ${
    //       71 + (isUnderMaintenance ? 50 : 0)
    //     }px !important`),
    //     (sideBarWrapper.style.cssText +=
    //       'height : 100%; padding-bottom: 80px; '))
    //   : ''

    // removes the eventlistener on equivalent of 'ComponenetWillUnmount'
    return function cleanup() {
      window.removeEventListener('resize', () => {
        setHeaderBottomCoordinate(header?.getBoundingClientRect().bottom);
      })
    };
  })

  // setting sidebar to close when Publish Modal opens
  useEffect(() => {
    const publishButton = document.getElementById('publish-button')

    publishButton && publishButton.addEventListener('click', publishModalToggle)

    function publishModalToggle() {
      const sidebarButtonToggle = document.querySelector(
        '[aria-label="toggles cms sidebar"]'
      )
      // get url path
      let sidebar
      let pathArray = window.location.pathname.split('/')
      let pagePath = pathArray[4]

      if (pagePath === 'staff' || pagePath === 'multi-location-staff') {
        sidebar = document.querySelector('.sidebar')
        sidebar = sidebar && !sidebar.classList.contains('closed')
      } else {
        sidebar = document.querySelector(`div[class*='SidebarContainer']`)
        sidebar = sidebar.hasAttribute('open')
      }
      sidebar && sidebarButtonToggle.click()
      toggle()
    }
  }, [])

  let handleSiteSelection = (group) => {
    let siteData = {
      siteId: group.siteId,
      siteURL: group.siteURL,
      yextId: group.yextId,
      name: group.name,
    }

    history.push(
      `/site/${group.siteId}/website-page/main/${group.name}`,
      siteData
    )
    setShowDropdown(false)
    window.location.reload()
  }

  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState)

  const homeURL = selectedSite.siteId
    ? `/site/${selectedSite.siteId}/website-page/main/${selectedSite.name}`
    : '/dashboard'
  return (
    <header className="bg-seaweed shadow-outer w-full z-10 fixed header-top" style={{ top: `${emergencyBannerBottomCoordinate}px` }}>
      <div className="flex w-full px-6 py-2">
        <div className="w-1/4 pr-4 m-auto flex-column">
          <a href={homeURL}>
            <img src={NVALogo} className="w-64 border-gray-600 border-t-1" />
          </a>
          {/* {!isCurrentUserAdmin && groups && groups.length > 1 && (
            <div className="pt-2">
              <a
                href={homeURL}
                className="focus:outline-none flex flex-row items-center text-xs text-white "
              >
                <ArrowLeft className="w-4 mr-2 transform rotate-180 fill-current text-white" />{' '}
                Back to Dashboard
              </a>
            </div>
          )} */}
        </div>
        <div className="w-2/4 flex flex-row m-auto justify-center">
          <div className="my-auto mr-4">
            <button
              id="publish-button"
              className="w-32 h-40 px-16 text-teal-600 bg-white ml-6 border-l border-gray-200 hover:bg-white"
            // disabled={!isAlertActive ? 'disabled' : ''}
            >
              Publish
            </button>
            <Modal
              isSaving={isSaving}
              isShowing={isShowing}
              hide={toggle}
              savingPublishedChanges={savingPublishedChanges}
              user={user}
            />
          </div>
          {selectedSite && (
            <div className="flex flex-col">
              <div className="flex items-center">
                <label
                  htmlFor="site-dropdown"
                  className="text-white text-right font-semibold text-sm mr-2"
                >
                  Select your site:
                </label>
                <button
                  id="site-dropdown"
                  className="flex flex-row text-white focus:outline-none"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  {selectedSite.name}{' '}
                  <ChevronDown className="w-6 pl-4 mt-2 text-white" />
                </button>
              </div>
              {showDropdown ? (
                <div className="absolute flex flex-col pb-6 bg-white shadow-2xl dropdown dropdown-contents z-10">
                  {groups.map((group, index) => (
                    <div
                      className="flex flex-col py-2 cursor-pointer hover:bg-teal-100 hover:text-teal-500"
                      key={index}
                      onClick={() => handleSiteSelection(group)}
                    >
                      <span className="mx-6"> {group.name} </span>
                      <span className="mx-6 font-light text-gray-400">
                        Location #{group.yextId}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
              <div>
                <label className="mt-4 mr-2 text-sm text-right text-white">
                  Location# {selectedSite.yextId}
                </label>
                <label>
                  <a
                    className="text-sm text-white underline"
                    href={baseSiteURL}
                    target="_blank"
                  >
                    {selectedSite.name}
                  </a>
                  <LaunchIcon
                    className="m-1 text-white"
                    style={{
                      width: '1rem',
                    }}
                  />
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-end w-1/4 p-2 m-auto">
          <div className="flex flex-row">
            <SupportDropDown userId={userId} />
            <UserDropDown />
          </div>
        </div>
      </div>
      {isCurrentUserAdmin && (
        <NavMenu style={{ paddingLeft: '1.5rem' }}>
          <NavList style={{ positon: 'relative', left: '1rem' }}>
            <NavItem linkText={'Dashboard'} linkPath={'/dashboard'} />
            <NavItem linkText={'Users'} linkPath={'/users'} />
            <NavItem linkText={'Change Log'} linkPath={'/log'} />
            <NavItem linkText={'Settings'} linkPath={'/settings'} />
          </NavList>
        </NavMenu>
      )}
    </header>
  )
}

export default Header
