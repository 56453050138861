import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import ImageCropper from '../../Blocks/ImageCropper'
import CaptureIcon from '-!svg-react-loader?name=Icon!../../../static/images/camera-solid.svg'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Showdown from 'showdown'
import { Link } from 'react-router-dom'

import { Paragraph } from '../../../pages/WebsitePagePage/WebsitePageHoursEditForm.styles.js'

import { fileMessages } from '../../../constants/messages/en'

const StaffDetails = (props) => {
  let converter = new Showdown.Converter()

  const [imageToCrop, setCropImage] = useState(
    props.data[props.group].staffMembers[props.staff].staffImage
  )
  const [croppedImage, setCroppedImage] = useState(null)
  const [nameLengthError, setNameLengthError] = useState('')
  const [shortBioData, setShortBioData] = useState('')
  const [shortBioDataHTML, setShortBioDataHTML] = useState('')
  const [shortBioDataLength, setShortBioLength] = useState(0)
  const [longBioData, setLongBioData] = useState('')

  const masterImages = process.env.MASTER_ENTRIES.split(',')
  const isNewStaff = props.data[props.group].staffMembers[props.staff].new

  useEffect(() => {
    let shortBioJson =
      props.data[props.group].staffMembers[props.staff].shortBio?.json || ''
    let htmlString = documentToHtmlString(shortBioJson)
    let htmlDom = new DOMParser().parseFromString(htmlString, 'text/html')
    truncateNode(htmlDom, 50)
    setShortBioData(htmlDom.body.innerHTML)

    setShortBioLength(
      getTextFromHTML(documentToHtmlString(shortBioJson)).length
    )
    setLongBioData(
      props.data[props.group].staffMembers[props.staff].longBio
        ? documentToHtmlString(
            props.data[props.group].staffMembers[props.staff].longBio.json
          )
        : ''
    )
  }, [])

  const truncateNode = (htmlDom, limit) => {
    if (htmlDom.nodeType === Node.TEXT_NODE) {
      htmlDom.textContent = htmlDom.textContent.substring(0, limit)
      return limit - htmlDom.textContent.length
    }
    htmlDom.childNodes.forEach((child) => {
      limit = truncateNode(child, limit)
    })
    return limit
  }

  const getTextFromHTML = (text) => {
    if (text && text.length > 0) {
      let formattedText = converter.makeHtml(text)
      var para = new DOMParser().parseFromString(formattedText, 'text/html')
      return para.body.innerText
    }
    return ''
  }

  const onSelectFile = async (e) => {
    let imageStaff = {
      isFileSelected: false,
      selectedImage: undefined,
    }

    imageStaff.isFileSelected = true

    if ((e.target.files && e.target.files.length > 0) || croppedImage) {
      if (e.target.files[0].size >= 2097152) {
        alert(fileMessages.FILE_TOO_BIG)
        return
      } else {
        props.setSaveButtonDisable(true)
        props.setIsUpdateStaffImage(true)
        var image64
        let reader = new FileReader()
        reader.addEventListener('load', () => {
          setCropImage(reader.result)
        })
        reader.readAsDataURL(e.target.files[0])
        reader.onload = function () {
          var binary = atob(reader.result.split(',')[1])
          var array = []
          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
          }

          image64 = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
          if (image64) {
            imageStaff.selectedImage = image64
            props.updateStaffImageInfo(imageStaff)
          }
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      }
    }
  }

  async function get64image(blobURL) {
    const response = await fetch(blobURL)
    const blob = await response.blob()

    return blob
  }
  var image64Cropped
  const onCrop = async (cropped) => {
    get64image(cropped).then((data) => {
      const reader = new FileReader()
      reader.readAsDataURL(data)
      reader.onloadend = function () {
        const oldCroppedValue = croppedImage
        setCroppedImage(data)
        if (oldCroppedValue) {
          props.data[props.group].staffMembers[props.staff].isCropped = true
          props.updateStaffImage(reader.result)
        }
      }

      reader.onload = function () {
        var binary = atob(reader.result.split(',')[1])
        var array = []
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i))
        }

        image64Cropped = new Blob([new Uint8Array(array)], {
          type: 'image/jpeg',
        })

        props.data[props.group].staffMembers[
          props.staff
        ].newCroppedImage = image64Cropped
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    })
  }

  let staffGroupNoShortBio =
    props.data[props.group].staffMembers[0].staffVariation === 'Variation-B'

  const handleNameChange = (data) => {
    props.setSaveButtonDisable(false)
    setNameLengthError('')
    if (data.length == 0) {
      setNameLengthError(fileMessages.NAME_LENGTH_ERROR)
      props.setSaveButtonDisable(true)
    }
  }

  return (
    <div className="staff-form">
      <div>
        <div id="staff-title" style={{ marginBottom: '20px' }}>
          <h2 className="sidebar-header">Edit Staff Member</h2>
          <Paragraph>
            Change the information about the staff member here. Remember that
            not all templates will display a Short Biography. In addition, it is
            not possible to preview the Full Biography in Easy Editor, but when
            published it will appear on the live site.
          </Paragraph>
        </div>
        <div id="staff-staffImage" style={{ marginBottom: '20px' }}>
          <ImageCropper
            image={imageToCrop}
            updateImage={onCrop}
            disabled={
              !props.data[props.group].staffMembers[props.staff]
                .isFileSelected &&
              masterImages.includes(
                props.data[props.group].staffMembers[props.staff]
                  .staffImageSectionId
              )
            }
          />

          <label
            style={{
              textDecoration: 'underline',
              color: '#0a8287',
              display: 'flex',
              marginTop: '10px',
              cursor: 'pointer',
            }}
          >
            <CaptureIcon className="w-4 fill-current text-teal-500 mr-2" />{' '}
            Upload photo{' '}
            <input
              className="pb-8 hidden"
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
          </label>
        </div>
        <div
          id="staff-name"
          className="h-70 text-gray-400 active:text-teal-500"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ color: '#0a8287' }}>* NAME</span>
          </div>
          <input
            type="text"
            maxLength="50"
            defaultValue={
              props.data[props.group].staffMembers[props.staff].name
            }
            onChange={() => {
              props.updateStaffName(event.target.value)
              handleNameChange(event.target.value)
            }}
            className="focus:text-black border-0 focus:outline-none"
          />
          <p style={{ color: 'unset', textAlign: 'right' }}>{`${
            props.data[props.group].staffMembers[props.staff].name.length
          }/50`}</p>
        </div>
        {nameLengthError && (
          <p className="text-orange-700 mb-20 -mt-1">{nameLengthError}</p>
        )}

        <div
          id="staff-title"
          className="h-70 active:text-teal-500"
          style={{ marginBottom: '20px' }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ color: '#0a8287' }}>TITLE</span>
          </div>
          <input
            type="text"
            defaultValue={
              props.data[props.group].staffMembers[props.staff].title
            }
            onChange={() => props.updateStaffTitle(event.target.value)}
            className="focus:outline-none"
            maxLength="50"
          />
          <p style={{ color: 'unset', textAlign: 'right' }}>{`${
            props.data[props.group].staffMembers[props.staff].title
              ? props.data[props.group].staffMembers[props.staff].title.length
              : 0
          }/50`}</p>
        </div>

        {!staffGroupNoShortBio ? (
          <div id="staff-shortBio" style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ color: '#0a8287' }}>SHORT BIOGRAPHY</span>
            </div>
            <div>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: ['bold', 'italic'],
                }}
                data={shortBioData}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  let currText = getTextFromHTML(data)
                  if (currText.length >= 51) {
                    editor.setData(shortBioDataHTML)
                  } else {
                    props.updateStaffShortBio(data)
                    setShortBioDataHTML(data)
                    setShortBioLength(getTextFromHTML(data).length)
                  }
                }}
                className="border-0 hello"
              />
              <p
                style={{ color: 'unset', textAlign: 'right' }}
              >{`${shortBioDataLength}/50`}</p>
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: '20px', marginTop: '20px' }}>
            <span style={{ color: '#0a8287' }}>SHORT BIOGRAPHY</span>

            <p style={{ color: '#cecece', paddingTop: '10px' }}>
              The current Staff Group variation does not support{' '}
              <strong>Short Biography</strong> preview. Please,{' '}
              <Link
                to={'/contact'}
                className="text-gray-400 underline hover:text-gray-900"
              >
                Contact our Support
              </Link>{' '}
              to change that (if needed).
            </p>
          </div>
        )}
      </div>
      <div id="staff-longBio" style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ color: '#0a8287' }}>FULL BIOGRAPHY</span>
        </div>
        <div>
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: [
                // 'heading',
                // '|',
                'bold',
                'italic',
                // 'link',
                // 'bulletedList',
                // 'numberedList',
                // 'blockQuote',
              ],
            }}
            data={longBioData}
            onChange={(event, editor) => {
              const data = editor.getData()
              props.updateStaffLongBio(data)
            }}
          />
        </div>
      </div>

      <div
        style={{
          borderTop: '1px solid #d2d2d2',
          height: '100%',
          display: 'flex',
          alignContent: 'flex-end',
          marginTop: '1rem',
        }}
      >
        <button
          onClick={() => props.deleteStaff(props.isOnlyStaff)}
          disabled={isNewStaff}
        >
          <FontAwesomeIcon
            icon={faTrash}
            color={isNewStaff ? '#e2e2e2' : '#cb3a3c'}
          />
          <span
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              marginTop: '5px',
              textDecoration: 'underline',
              color: isNewStaff ? '#e2e2e2' : '#cb3a3c',
            }}
          >
            Delete staff member
          </span>
        </button>
      </div>
    </div>
  )
}

export default StaffDetails
