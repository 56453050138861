import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './Accordion.css'
import ForwardArrow from '-!svg-react-loader?name=Icon!../../static/images/next.svg'
import { getHoursAccordionData, getStaffAccordionData } from './helper'
import { useGlobalState } from '../../hooks/state/state'

let Accordion = ({ accordionFor, data, url, selectedSite }) => {
  const [setActive, setActiveState] = useState(false)
  const [setHeight, setHeightState] = useState('0px')
  const [accordionData, setAccordionData] = useState({})

  const [globalStaffPages] = useGlobalState('staffPages')

  const content = useRef(null)

  useEffect(() => {
    if (accordionFor && data.length > 0 && url && selectedSite) {
      if (accordionFor == 'multiLocationHours') {
        setAccordionData(
          getHoursAccordionData(accordionFor, data, url, selectedSite)
        )
      } else if (accordionFor == 'multiLocationStaff' && url && selectedSite) {
        setAccordionData(
          getStaffAccordionData(
            accordionFor,
            globalStaffPages,
            url,
            selectedSite
          )
        )
      }
    }
  }, [accordionFor, globalStaffPages])

  function toggleAccordion() {
    setActiveState(!setActive)
    setHeightState(setActive ? '0px' : `${content.current.scrollHeight}px`)
  }

  return accordionFor && data.length > 0 && url ? (
    <React.Fragment>
      <Link
        className="flex flex-row p-3 hover:bg-teal-300 justify-between"
        onClick={toggleAccordion}
      >
        {accordionData.title}{' '}
        <ForwardArrow
          className={`white-arrow w-3 my-1 ml-2 text-white hover:block ${
            setActive ? 'accordian-open' : 'accordian-close'
          }`}
        />
      </Link>
      <div
        ref={content}
        style={{
          maxHeight: `${setHeight}`,
          overflow: setActive ? null : 'hidden',
        }}
        className="accordion__content"
      >
        {accordionData.options &&
          accordionData.options.length > 0 &&
          accordionData.options.map((option, index) => {
            return (
              <Link
                to={{
                  pathname: option.pathName,
                  [accordionFor]: option[accordionFor],
                }}
                className="flex flex-row p-3 accordion__anchor justify-between"
                onClick={option.onClick}
                key={index}
              >
                {option.name}{' '}
                <ForwardArrow className="white-arrow w-3 my-1 ml-2 text-white hover:block" />
              </Link>
            )
          })}
      </div>
    </React.Fragment>
  ) : null
}

export default Accordion
