import React from 'react'
import { Link } from 'react-router-dom'
import NVALogo from '../../static/images/NVA-plain.png'

function SuccessModal({senderEmail, setIsShowingModal}) {

  return<div className="text-center">
    <div className="flex justify-center mb-8">
      <img src={NVALogo} className="w-64" />
    </div>
    <div className="mb-12">
      <p className="font-semibold mb-4">Thank you for your message!</p>
      <p className="">Please check for our response in 24 hours or less at</p>
      <p className="text-teal-600 underline">{senderEmail}</p>
    </div>
    <div>
      <button className="bg-teal-500 text-white p-2 w-32" onClick={() => setIsShowingModal(false)}>Ok</button>
    </div>
  </div>
}

export default SuccessModal;