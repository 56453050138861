import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
// import EditUserCell from 'src/components/EditUserCell'
import EditUserCellRest from 'src/components/EditUserCellRest'

const EditUserPage = ({ role,userid }) => {
  if (role != 1) {
    return (
      <UpdatedMainLayout>
        <h1>sorry, you are not allowed to view this page</h1>
      </UpdatedMainLayout>
    )
  }
  return (
    <UpdatedMainLayout>
      {/* <EditUserCell id={userId} /> */}
      <EditUserCellRest id={userid} />
    </UpdatedMainLayout>
  )
}

export default EditUserPage
