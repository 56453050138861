import Navbar from '../../Navbar/Navbar'
import Logo from '../../Logo/Logo'
import { HeaderRow } from './Header.styles'
import { useGlobalState } from 'src/hooks/state/state'
import { useEffect } from 'react'

const Header = ({ userId }) => {
  const [isUnderMaintenance] = useGlobalState('isUnderMaintenance')
  useEffect(() => {
    if (isUnderMaintenance) {
      let emerElem = document.querySelector('#emergency-banner-easyeditor')
      if (emerElem) {
        emerElem.style.cssText += 'position: unset'
      }
    }
  }, [])
  return (
    <HeaderRow>
      <Logo />
      <Navbar userId={userId} />
    </HeaderRow>
  )
}

export default Header
