import React from 'react'
import UpdatedMainLayout from 'src/layouts/UpdatedMainLayout/UpdatedMainLayout'
import screenshot from './scrnsht.png'
import UserGroupsCellRest from 'src/components/UserGroupsCellRest'

const DashBoardPage = ({ userid }) => {
  return (
    <UpdatedMainLayout>
      <UserGroupsCellRest imgSrc={screenshot} id={userid} />
    </UpdatedMainLayout>
  )
}

export default DashBoardPage
