import React, { useEffect, useState, useCallback } from 'react'
import { Redirect, Route } from 'react-router-dom'
// import { useQuery } from '@redwoodjs/web'
import { getUserByEmail, createUser } from '../src/api/users'

const PrivateRoute = ({ account, component: Component, ...rest }) => {
  const [user, setUser] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  /**
   * Create new user using REST query.
   * @param {Object} data User form data
   */
  const createNewUser = useCallback(async () => {
    try {
      setLoading(true)
      const date = new Date().toISOString()
      const data = {
        email: account.userName,
        updatedAt: date,
        name: account.name,
        nickname: account.name,
        picture: '',
        userId: account.accountIdentifier,
        roleId: parseInt(2, 10),
      }

      const query = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:')
      await createUser(query)
      setLoading(false)
    } catch (e) {
      setError(e)
    }
  }, [account])
  /**
   * Get a user by email from REST API.
   */
  const getUserRest = useCallback(async () => {
    setLoading(true)
    const usersRest = await getUserByEmail({ email: account.userName })
    let { data } = usersRest
    // if user doesn't have an account yet, create one
    if (usersRest.data.userByEmail.length === 0) {
      await createNewUser()

      // once new user us created, try to get this user's data
      const foundRecentlyCreatedUser = await getUserByEmail({
        email: account.userName,
      })
      let { data } = foundRecentlyCreatedUser
      setUser(data.userByEmail[0])
      setLoading(false)

      // user already exists
    } else {
      setUser(data.userByEmail[0])
      setLoading(false)
    }
    return usersRest
  }, [account, createNewUser])

  useEffect(() => {
    if (account !== null) getUserRest()
  }, [getUserRest, account])

  // TODO: replacing current gql with REST
  // const QUERY = gql`
  //   query FIND_USER_BY_EMAIL($email: String!) {
  //     userEmail: userEmail(email: $email) {
  //       id
  //       email
  //       roleId
  //     }
  //   }
  // `

  // const { loading, error, data } = useQuery(QUERY, {
  //   variables: { email: account.userName },
  // })

  return (
    <Route
      {...rest}
      render={(props) =>
        account ? (
          <Component
            userid={user && user.id}
            role={user && user.roleId}
            {...props}
            account={account}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
