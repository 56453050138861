import React, { useState } from 'react'
import Modal from '../ContactModal'
import { ModalContext } from '../../context/ModalContext'
import useModal from '../../hooks/useModal'
import VideoModal from '../VideoModal'
import ChevronDown from '-!svg-react-loader?name=Icon!../../static/images/chevron-down-solid.svg'
import HelpIcon from '@material-ui/icons/Help'
import { truncate } from 'lodash'

const SupportDropDown = ({ userId }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [isShowing, setIsShowingModal] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [emailSuccess, setEmailSuccess] = useState(false)

  let showSupportModal = () => {
    setIsShowingModal(true)
    setEmailSuccess(false)
  }

  return (
    <div className="my-auto">
      <button
        className="flex flex-row text-white focus:outline-none mr-6"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <HelpIcon className="pr-2" />
        Support
        <ChevronDown className="w-4 pl-2 mt-2 text-white" />
      </button>
      {showDropdown ? (
        <div className="absolute flex flex-col pb-6 bg-white shadow-2xl support-dropdown-contents z-10">
          <div className="py-2 cursor-pointer hover:bg-teal-100 hover:text-teal-500">
            <a
              className="mx-6 font-light"
              target="_blank"
              rel="noreferrer"
              href="https://www.gpconnect.nva.com/easy-editor-faqs"
            >
              General Practice FAQ
            </a>
          </div>
          <div className="py-2 cursor-pointer hover:bg-teal-100 hover:text-teal-500">
            <a
              className="mx-6 font-light"
              target="_blank"
              rel="noreferrer"
              href="http://www.nvacfconnect.com/easy-editor"
            >
              Specialty+ER FAQ
            </a>
          </div>
          <div className="py-2 cursor-pointer hover:bg-teal-100 hover:text-teal-500">
            <a
              className="mx-6 font-light"
              target="_blank"
              rel="noreferrer"
              href="http://www.nvaequineconnect.com/easy-editor"
            >
              Equine FAQ
            </a>
          </div>
          <div
            className="py-2 cursor-pointer hover:bg-teal-100 hover:text-teal-500"
            onClick={() => setShowVideo(!showVideo)}
          >
            <span className="mx-6 font-light">Training Video</span>
          </div>
          <div
            className="py-2 cursor-pointer hover:bg-teal-100 hover:text-teal-500"
            onClick={showSupportModal}
          >
            <span className="mx-6 font-light">Contact Support</span>
          </div>
        </div>
      ) : (
        ''
      )}
      {isShowing ? (
        <Modal
          setIsShowingModal={setIsShowingModal}
          emailSuccess={emailSuccess}
          setEmailSuccess={setEmailSuccess}
        />
      ) : (
        ''
      )}
      {showVideo ? (
        <VideoModal setShowVideo={setShowVideo} userid={userId} />
      ) : (
        ''
      )}
    </div>
  )
}

export default SupportDropDown
