// @flow
/**
 * @file Manages the messages
 */

const bannerMessages = {
  CONFIRM_LOSE_UNSAVED_BANNER:
    'You are about to lose all your unsaved changes in Emergency Banner.  Are you sure you want to proceed?',
}

const fileMessages = {
  FILE_TOO_BIG: 'File too big',
  NAME_LENGTH_ERROR:'Name is a required field and cannot be empty'
}

const staffMessages = {
  CONFIRM_DELETE_GROUP: 'Are you sure you want to delete this group?',
  CONFIRM_DELETE_STAFF_MEMBER:
    'Are you sure you want to delete this staff member?',
  CONFIRM_LOSE_UNSAVED_STAFF_INFO:
    'You are about to lose all your unsaved changes in Staff Information.  Are you sure you want to proceed?',
  GROUP_NEEDS_STAFF_MEMBER:
    'Group should have at least one staff member. Do you want to add staff?',
  NO_EMPTY_GROUPS:
    'Staff groups cannot be empty. Deleting the only staff member of this group will also delete the group.',
  STAFF_MEMBER_SAVE_OR_DELETE: 'Staff Member not saved, please save or delete',
  STAFF_MEMBER_EMPTY_NOT_SAVED: 'Staff member empty, and was not saved',
}

const hoursMessages = {
  CONFIRM_LOSE_UNSAVED_HOURS:
    'You are about to lose all your unsaved changes in Hours of Operation.  Are you sure you want to proceed?',
}

const loginMessages = {
  USE_CHROME_BROWSER:
    'Easy Editor works best in Google Chrome, at full screen, and not in incognito mode.',
}

const userMessages = {
  CONFIRM_DELETE_USER: 'Are you sure you want to delete user ',
  CONFIRM_DELETE_ENTRY: 'Are you sure you want to delete this entry?',
}
const siteMessages = {
  SITE_HAS_USER: 'The user has already been added to this site.',
}

export {
  bannerMessages,
  fileMessages,
  hoursMessages,
  loginMessages,
  siteMessages,
  staffMessages,
  userMessages,
}
