import { NavLink } from 'react-router-dom'
// import { NavMenu } from './NavItem.styles'

const NavItem = ({ linkPath, linkText }) => {
  return (
    <NavLink
      to={linkPath}
      activeClassName="active"
      className="flex items-center w-auto pb-4 mx-16 mt-4 text-base border-b-8 border-transparent text-alabaster"
    >
      {linkText}
    </NavLink>
  )
}

export default NavItem
