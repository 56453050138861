import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripLines, faTrash } from '@fortawesome/free-solid-svg-icons'
import PawLoader from '../../PawLoader'

const GroupDetails = (props) => {
  const staffLength = props.data[props.group]?.staffMembers.length

  const [dragging, setDragging] = useState(false)

  const onDragOver = (ev) => {
    ev.preventDefault()
  }

  const onDragStart = (ev, key) => {
    setDragging(true)
    ev.dataTransfer.setData('key', key)
  }

  const onDrop = (ev, destKey) => {
    setDragging(false)
    let sourceKey = ev.dataTransfer.getData('key')
    props.reorderStaff(sourceKey, destKey)
  }

  const sendMsg = (jsonMsg) => {
    let win = window.frames['websitePreviewFrame']
    const strMsg = JSON.stringify(jsonMsg)
    win.postMessage(strMsg, '*')
  }

  return props.data[props.group] ? (
    <div className="staff-form">
      <div>
        <div id="group-name" style={{ marginBottom: '20px' }}>
          <div>
            <h2 className="sidebar-header">Edit Staff Group</h2>
          </div>
          <input
            type="text"
            defaultValue={props.data[props.group].groupName}
            placeholder="Enter Group Name"
            onChange={() => props.updateGroupName(event.target.value)}
            maxLength="50"
          />
          <p style={{ fontSize: 'smaller', textAlign: 'right' }}>
            {`${props.data[props.group]?.groupName?.length}/50`}
          </p>
          <p className="sidebar-para">
            Drag and drop the names below to reorder your staff. Click on a name
            to edit that staff member. You can edit the group name above.
          </p>
        </div>

        <div id="group-add-staff" style={{ marginBottom: '20px' }}>
          <button
            onClick={() => props.addStaff()}
            className={props.addButtonDisabled ? 'disabled' : ''}
          >
            <span style={{ textDecoration: 'underline', color: '#0a8287' }}>
              + Add Staff
            </span>
          </button>
        </div>

        <div id="group-staffs" style={{ marginBottom: '40px' }}>
          {props.addButtonDisabled ? (
            <PawLoader />
          ) : props.data ? (
            props.data[props.group].staffMembers.map((item, key) => {
              if (!item.deleted) {
                return (
                  <div
                    key={key}
                    id={item.staffEntryId}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 3fr',
                      marginBottom: '20px',
                      cursor: 'grabbing',
                      padding: '5px',
                      alignItems: 'center',
                    }}
                    className={`${dragging ? 'showDropAreas' : ''}`}
                    draggable
                    onDragOver={onDragOver}
                    onDrop={(e) => onDrop(e, key)}
                    onDragStart={(e) => onDragStart(e, key)}
                  >
                    <FontAwesomeIcon icon={faGripLines} />
                    <button
                      onClick={() => {
                        sendMsg({ scrollToStaffId: item.staffEntryId })
                        props.goStaff(key, staffLength)
                      }}
                    >
                      <div style={{ textAlign: 'left' }}>
                        {item.name == '' ? 'Unnamed staff member' : item.name}
                      </div>
                    </button>
                  </div>
                )
              }
            })
          ) : null}
        </div>
      </div>
      <div
        style={{
          borderTop: '1px solid #d2d2d2',
          display: 'flex',
          alignContent: 'flex-end',
        }}
      >
        <button
          onClick={() => props.deleteGroup()}
          disabled={props.data[props.group].new || props.data[props.group].preventDeletion}
          className={props.data[props.group].new  || props.data[props.group].preventDeletion ? 'disabled' : ''}
        >
          <FontAwesomeIcon
            icon={faTrash}
            color={props.data[props.group].new ? '#e2e2e2' : '#cb3a3c'}
          />
          <span
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              marginTop: '5px',
              textDecoration: 'underline',
              color: props.data[props.group].new ? '#e2e2e2' : '#cb3a3c',
            }}
          >
            Delete group
          </span>
        </button>
      </div>
    </div>
  ) : (
    ''
  )
}

export default GroupDetails
