import { useAuth } from '@redwoodjs/auth'
import * as Msal from 'msal'
import React, { useEffect, useState } from 'react'
import { msalConfig } from '../../../auth/authConfig'
import { getUserByEmail } from '../../api/users'
import Header from './Navbar/Header/Header'
import NavItem from './NavItem/NavItem'
import './UpdatedHeader.css'
import { NavList, NavMenu } from './UpdatedHeader.styles'
// eslint-disable-next-line no-unused-vars
const UpdatedHeader = ({ children, userId }) => {
  const [user, setUser] = useState(null)
  const { loading } = useAuth()

  if (loading) return null

  const myMSALObj = new Msal.UserAgentApplication(msalConfig)
  const account = myMSALObj.getAccount()

  // getting the user role
  useEffect(() => {
    async function getUserRole() {
      const foundRecentlyCreatedUser = await getUserByEmail({
        email: account.userName,
      })
      let { data } = foundRecentlyCreatedUser
      setUser(data.userByEmail[0])
    }
    getUserRole()
  }, [])
  //TODO: change that code to put all nav items under the same div
  return (
    <>
      <Header userId={userId} />
      <NavMenu>
        <NavList style={{ positon: 'relative', left: '1rem' }}>
          <NavItem linkText={'Dashboard'} linkPath={'/dashboard'} />
          {user && user.roleId && user.roleId == 1 ? (
            <NavItem linkText={'Users'} linkPath={'/users'} />
          ) : (
            ''
          )}

          {user && user.roleId && user.roleId == 1 ? (
            <NavItem linkText={'Change Log'} linkPath={'/log'} />
          ) : (
            ''
          )}
          {user && user.roleId && user.roleId == 1 ? (
            <NavItem linkText={'Settings'} linkPath={'/settings'} />
          ) : (
            ''
          )}
        </NavList>
      </NavMenu>
    </>
  )
}

export default UpdatedHeader
